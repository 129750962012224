import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import MaskedView from '@react-native-masked-view/masked-view';
import LinearGradient from 'react-native-linear-gradient';
import { BlurView } from 'expo-blur';
import LinkExpandedContext from './StuffList/LinkExpandedContext';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import useColorScheme from 'app/hooks/use-color-scheme';

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

const StatusBarBlurHeader = () => {
  const insets = useSafeAreaInsets();
  const { linkIsExpanded } = useContext(LinkExpandedContext);
  const animatedValue = useSharedValue(1);
  const colorScheme = useColorScheme();

  useEffect(() => {
    setTimeout(
      () => {
        animatedValue.value = withSpring(linkIsExpanded ? 0 : 1, {
          stiffness: 80,
          damping: 20,
        });
      },
      linkIsExpanded ? 0 : 400,
    );
  }, [animatedValue, linkIsExpanded]);

  const style = useAnimatedStyle(
    () => ({
      opacity: animatedValue.value,
    }),
    [animatedValue],
  );

  return (
    <MaskedView
      style={[styles.container, { height: insets.top * 1.25 }]}
      maskElement={
        <LinearGradient
          colors={['rgba(255, 255, 255, 1)', 'transparent']}
          style={styles.gradient}
          locations={[0.35, 1]}
        />
      }
    >
      <AnimatedBlurView
        intensity={32}
        tint={colorScheme === 'light' ? 'light' : 'dark'}
        style={[styles.container, style, { height: insets.top * 1.25 }]}
      />
    </MaskedView>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  gradient: {
    height: '100%',
    flex: 1,
  },
});

export default StatusBarBlurHeader;
