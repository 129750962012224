import SearchInput from 'app/components/SearchSheet/SearchInput';
import React, { useCallback, useState } from 'react';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import Logo from '../Logo';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import Text, { TextType } from '../Text';
import SearchSheetModal from '../SearchSheet/SearchSheetModal';
import { useSharedValue, withSpring } from 'react-native-reanimated';
import useIsMobile from 'app/hooks/use-is-mobile';
import { LINK_PADDING } from './useLayoutInfo';
import HeaderMenu from './HeaderMenu';

interface Props {
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  onChangeQueryKey: ReturnType<typeof useStuffsQuery>['onChangeQueryKey'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  displayName: string;
  isVisitor: boolean;
  username: string;
  contentPaddingVertical: number;
  contentInnerPaddingHorizontal: number;
  headerVisible: boolean;
}

const Header = ({
  onChangeQuery,
  onChangeQueryKey,
  resetQuery,
  parsedQuery,
  username,
  displayName,
  isVisitor,
  contentPaddingVertical,
  contentInnerPaddingHorizontal,
  headerVisible,
}: Props) => {
  const [search, setSearch] = useState('');
  const isIOS = Platform.OS === 'ios';
  const isMobile = useIsMobile();
  const showSearchModal = !isIOS;
  const showSearchInput = isIOS && isVisitor;
  const title = isVisitor ? displayName : 'My stuff';

  const visitorSearchInputPresentedAnimatedValue = useSharedValue(0);
  // It's always on for visitors as we're not showing the cancel button
  const visitorFocusedAnimatedValue = useSharedValue(1);

  const onVisitorFocusToggle = useCallback(
    (focused: boolean) => {
      visitorSearchInputPresentedAnimatedValue.value = withSpring(
        focused ? 1 : 0,
        {
          damping: 30,
          overshootClamping: false,
          restDisplacementThreshold: 0.001,
          restSpeedThreshold: 0.01,
          stiffness: 200,
          mass: 1,
        },
      );

      if (!parsedQuery.isActive) {
        setSearch('');
      }
    },
    [visitorSearchInputPresentedAnimatedValue, parsedQuery.isActive],
  );

  const content = [];

  if (Platform.OS === 'web') {
    content.push(
      <View
        style={{ paddingHorizontal: contentInnerPaddingHorizontal }}
        key="dots"
      >
        <Logo type="dots" animate />
      </View>,
    );
  }

  if (headerVisible) {
    content.push(
      <View
        style={[
          styles.header,
          { paddingHorizontal: contentInnerPaddingHorizontal },
        ]}
        key="content"
      >
        <Text
          numberOfLines={1}
          type={TextType.largeTitleEmphasized}
          style={{ flex: 1 }}
        >
          {title}
        </Text>
        {!isMobile && showSearchModal && (
          <SearchSheetModal isMobile={isMobile} />
        )}
        <View
          style={[styles.headerActions, isMobile && styles.headerActionsMobile]}
        >
          <HeaderMenu />
        </View>
      </View>,
    );
  }

  if (isMobile && showSearchModal) {
    content.push(
      <SearchSheetModal isMobile={isMobile} key="search_sheet_modal" />,
    );
  }

  if (showSearchInput) {
    content.push(
      <SearchInput
        key="search_input"
        search={search}
        setSearch={setSearch}
        parsedQuery={parsedQuery}
        onChangeQuery={onChangeQuery}
        onChangeQueryKey={onChangeQueryKey}
        resetQuery={resetQuery}
        username={username}
        isVisitor={isVisitor}
        style={styles.searchInput}
        suggestionsStyle={styles.searchInputSuggestions}
        presentedAnimatedValue={visitorSearchInputPresentedAnimatedValue}
        focusedAnimatedValue={visitorFocusedAnimatedValue}
        onFocusToggle={onVisitorFocusToggle}
        showCancel={false}
        containerWidth={Platform.select({
          web: undefined,
          // TODO: This should probably just be handled by SearchInput
          // NOTE: This number is hyper specific. Its based on the contentPaddingHorizontal (among
          // other things) returned from useLayoutInfo. But we have to modify that number to make weird
          // flashlist bugs go away. Ideally we'd use context to calculate this value once
          // and use it everywhere. It gets really confusing when we make slight padding changes.
          default: Dimensions.get('window').width - 26,
        })}
      />,
    );
  }

  if (!content.length) {
    return null;
  }

  return (
    <>
      <View
        style={[
          styles.container,
          {
            paddingHorizontal: LINK_PADDING,
            paddingTop: contentPaddingVertical,
          },
        ]}
      >
        {content}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 36,
    gap: 8,
    overflow: 'visible',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerActions: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  headerActionsMobile: {
    flex: 0,
  },
  headerButton: {},
  searchInput: {
    zIndex: 1,
  },
  searchInputSuggestions: {
    marginTop: 36,
  },
  Tips: {
    marginBottom: 16,
  },
});

export default React.memo(Header);
