import React, { useContext } from 'react';
import Button, { ButtonType } from '../Button';
import Ellipsis from '../Icons/Ellipsis';
import ContextMenu from '../ContextMenu/ContextMenu';
import { DropdownItem } from '../Dropdown';
import Person from '../Icons/Person';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import { useAuthStatus } from 'app/hooks/use-auth/use-auth-status';
import { Status } from 'app/hooks/use-auth/types';
import StuffListContext from './StuffListContext';
import Cog from '../Icons/Cog';
import CheckmarkCircle from '../Icons/CheckmarkCircle';
import useHasFeature from 'app/hooks/use-has-feature';

const HeaderMenu = () => {
  const navigation = useNavigation();
  const authStatus = useAuthStatus();
  const { isSelecting, setIsSelecting } = useContext(StuffListContext);
  const hasCollectionFeature = useHasFeature('collections');

  if (authStatus !== Status.USER) {
    return null;
  }

  if (!hasCollectionFeature) {
    return (
      <Button
        hitSlop={{ left: 16, top: 16, right: 16, bottom: 16 }}
        type={ButtonType.secondary}
        size="small"
        Icon={Person}
        onPress={() => navigation.navigate(Routes.SETTINGS)}
        shadow={false}
        rounded
      />
    );
  }

  if (isSelecting) {
    return (
      <Button
        type={ButtonType.secondary}
        onPress={() => setIsSelecting(false)}
        shadow={false}
        size="small"
        rounded
      >
        Cancel
      </Button>
    );
  }

  return (
    <ContextMenu
      actions={[
        <DropdownItem
          label="Select"
          Icon={CheckmarkCircle}
          onPress={() => setIsSelecting(true)}
          key="select"
        />,
        <DropdownItem
          label="Settings"
          Icon={Cog}
          last
          onPress={() => navigation.navigate(Routes.SETTINGS)}
          key="remove"
        />,
      ]}
    >
      {({ open }) => (
        <Button
          hitSlop={{ left: 16, top: 16, right: 16, bottom: 16 }}
          type={ButtonType.secondary}
          size="small"
          Icon={Ellipsis}
          onPress={open}
          shadow={false}
          rounded
        />
      )}
    </ContextMenu>
  );
};

export default HeaderMenu;
