import { useResource, useUpdate } from 'app/hooks/use-resource/use-resource';

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Platform, ScaledSize, StyleSheet, ViewStyle } from 'react-native';
import LinkData from '../LinkData';

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Reactions from './Reactions';
import useIsMobile from 'app/hooks/use-is-mobile';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import Pressable from '../Pressable';
import { LinkDataTransform } from './useLinkStyles';
import LinkExpanded from './LinkExpanded';
import { Portal } from '@gorhom/portal';
import { FullWindowOverlay } from 'react-native-screens';
import { SharedValue } from 'react-native-reanimated';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import StuffListContext from '../StuffList/StuffListContext';

const Link = ({
  linkId,
  style,
  width,
  scrollViewOffset,
  windowDimensions,
  onChangeQuery,
  isVisitor = false,
}: {
  linkId: string;
  width: number;
  style?: ViewStyle | (ViewStyle | false | undefined)[];
  isVisitor?: boolean;
  scrollViewOffset: SharedValue<number>;
  windowDimensions: ScaledSize;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}) => {
  // TODO: We disable fetch due to the order in which listeners of the resource store
  // are notified about the change in data. It seems it's not done in a single
  // render sweep, so somehow the hook is being notified of the non-existence of
  // the deleted entity before the StuffList is.
  const { link, ready } = useResource('link', linkId, { fetch: false });
  const { linkData, ready: linkDataReady } = useResource(
    'link_data',
    link ? link.relationships.link_data.data.id : null,
  );
  const updateLink = useUpdate('link');
  const stuffListContext = useContext(StuffListContext);

  const isMobile = useIsMobile();
  const useGestureHandler = !(isMobile && Platform.OS === 'web');

  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const linkDataRef = useRef<Animated.View | null>(null);

  const expandedLinkDataTransform = useSharedValue<LinkDataTransform>({
    x: 0,
    y: 0,
    progress: 0,
    expandedWidth: 1,
    expandedHeight: 1,
    collapsedWidth: 1,
    collapsedHeight: 1,
    scale: 1,
  });

  const scrollLinkIntoViewIfNeeded = useCallback(() => {
    if (Platform.OS === 'web' && linkDataRef.current) {
      // TODO: If we replace layout with a ref, we could access it directly, rather
      // than having to measure again. We have to measure again because by the time
      // this function is called (when the expand animation has finished), the onExpand
      // function has a stale reference to the layout.
      linkDataRef.current.measure((x, y, _width, height, pageX, pageY) => {
        if (pageY < 0 || pageY + height > windowDimensions.height) {
          linkDataRef.current.scrollIntoView({
            block: 'nearest',
            behavior: 'smooth',
          });
        }
      });
    } else if (Platform.OS === 'ios') {
      // TODO: Support scrolling the link into view via FlashList's scrollToItem()
    }
  }, [windowDimensions.height]);

  const onExpand = useCallback(() => {
    // Scroll the background if needed. We do this via timeout rather than the animation
    // completion callback as that takes too long.
    setTimeout(scrollLinkIntoViewIfNeeded, 100);
    setIsExpanded(true);
  }, [scrollLinkIntoViewIfNeeded]);

  const onClose = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const onHover = useCallback(() => {
    setIsHovered(true);
  }, []);

  const linkDataAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          scale:
            expandedLinkDataTransform.value.progress === 0
              ? expandedLinkDataTransform.value.scale
              : 1,
        },
      ],
      // Setting 0.05 as the minimum ensures this is still rendered when the
      // expand animation starts. iOS was too fast and there was a frame where
      // it wouldn't render, but the collapsed had yet to animate in.
      // opacity: interpolate(expandAnimatedValue.value, [0, 0.01], [1, 0]),
      opacity: expandedLinkDataTransform.value.progress ? 0 : 1,
    };
  }, [expandedLinkDataTransform]);

  if (!ready || !linkDataReady) {
    return null;
  }

  const hasReaction =
    link.attributes.reaction_love ||
    link.attributes.reaction_flag ||
    link.attributes.reaction_emphasis;

  const config = {
    stiffness: 200,
    damping: 15,
    restDisplacementThreshold: 0.001,
    restSpeedThreshold: 0.01,
  };

  const longGesture = Gesture.Tap()
    .runOnJS(true)
    .maxDuration(10000000000)
    .onBegin(() => {
      expandedLinkDataTransform.value = withSpring(
        {
          ...expandedLinkDataTransform.value,
          scale: 0.95,
        },
        config,
      );
    })
    .onFinalize(() => {
      expandedLinkDataTransform.value = withSpring(
        {
          ...expandedLinkDataTransform.value,
          scale: 1,
        },
        config,
      );
    })
    .onEnd(() => {
      ReactNativeHapticFeedback.trigger('impactMedium');
      onExpand();
    });

  const expanded = isExpanded && (
    <StuffListContext.Provider value={stuffListContext}>
      <LinkExpanded
        // Without this there seems to be some kind of shared state between LinkExpanded instances
        // for different links. The bug we had was that when opening a new link as another was closing
        // (which we allow due to disabling pointerevents during the close animation), somehow the `open`
        // state seemed to be shared. Using a key stops that from happening. It's possible its somehow
        // related to FlashList's reuse of things.
        key={link.id}
        onClose={onClose}
        isMobile={isMobile}
        link={link}
        linkData={linkData}
        isVisitor={isVisitor}
        windowDimensions={windowDimensions}
        tapGesture={longGesture}
        updateLink={updateLink}
        expandedLinkDataTransform={expandedLinkDataTransform}
        collapsedRef={linkDataRef}
        scrollViewOffset={scrollViewOffset}
        onChangeQuery={onChangeQuery}
        linkDataCollapsed={
          <>
            <LinkData
              linkDataId={link.relationships.link_data.data.id}
              hovered={isHovered}
              width={width}
              onChangeQuery={onChangeQuery}
            />
            {hasReaction && (
              <Reactions
                style={styles.reactions}
                link={link}
                update={updateLink}
                disabled={isVisitor}
              />
            )}
          </>
        }
      />
    </StuffListContext.Provider>
  );

  const linkDataContainer = (
    <Animated.View
      style={[linkDataAnimatedStyle, styles.linkDataContainer]}
      ref={linkDataRef}
    >
      <LinkData
        linkDataId={link.relationships.link_data.data.id}
        hovered={isHovered}
        width={width}
        onChangeQuery={onChangeQuery}
      />
      {hasReaction && (
        <Reactions
          style={styles.reactions}
          link={link}
          update={updateLink}
          disabled={isVisitor}
        />
      )}
    </Animated.View>
  );

  return (
    <>
      <Pressable
        onHoverIn={onHover}
        onHoverOut={(event) => {
          // If a nested hoverOut event happens, it'll call other currently hovered hoverOuts
          // but with a `pointerenter` event. So we can use that to support nested hovers.
          if (event.type !== 'pointerleave') {
            return;
          }
          setIsHovered(false);
        }}
        style={[styles.container, style]}
        onPress={!useGestureHandler ? onExpand : undefined}
        pointerEvents={isExpanded ? 'none' : 'auto'}
      >
        {useGestureHandler ? (
          <GestureDetector gesture={longGesture}>
            {linkDataContainer}
          </GestureDetector>
        ) : (
          linkDataContainer
        )}
      </Pressable>
      {expanded && (
        <Portal hostName="expandedLinks">
          {Platform.OS === 'ios' && isVisitor ? (
            <FullWindowOverlay style={StyleSheet.absoluteFillObject}>
              {expanded}
            </FullWindowOverlay>
          ) : (
            expanded
          )}
        </Portal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: { position: 'relative' },
  linkDataContainer: {
    scrollMarginTop: 7,
    scrollMarginBottom: 7,
  },
  reactions: {
    position: 'absolute',
    top: -8,
    left: -4,
  },
  reactionsExpanded: {
    position: 'absolute',
    top: -46,
    left: 0,
  },
});

export default React.memo(Link);
