import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import { StyleSheet } from 'react-native';

interface Props {
  width?: number;
  height?: number;
  color?: Colors;
}

const EllipsisCircle = ({
  color = 'LayeredGray',
  width = 19,
  height = 5,
}: Props) => {
  const colorToUse = useColor(color);

  return (
    <ThemeView
      darkStyle={styles.circleDark}
      lightStyle={styles.circleLight}
      style={{
        width,
        height,
        borderRadius: width / 2,
      }}
    >
      <Svg width={12} viewBox="0 0 19 5">
        <Path
          d="M4.27 2.85c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2Zm12-2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
          fill={colorToUse}
        />
      </Svg>
    </ThemeView>
  );
};

const styles = StyleSheet.create({
  ...makeThemeStyle(
    'circle',
    {
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1.5,
    },
    {
      borderColor: 'LayeredSubtleGray',
    },
  ),
});

export default EllipsisCircle;
