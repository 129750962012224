import React, { useState } from 'react';
import {
  ColorSchemeName,
  Platform,
  PressableProps,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import Text, { TextType } from '../Text';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import { Colors } from 'app/constants/colors';
import ShadowView, { ShadowType } from '../ShadowView';
import Routes from '../Navigator/ROUTES';
import { useLinkProps } from '@react-navigation/native';
import useColor from 'app/hooks/use-color';
import RefreshControl from '../RefreshControl';
import Pressable from '../Pressable';
import ButtonType from './ButtonTypes';
import BlurView from '../BlurView';

interface Props extends PressableProps {
  type: ButtonType;
  children?: string;
  size?: 'xs' | 'small' | 'normal' | 'large';
  to?: { screen: Routes };
  href?: string;
  Icon?: React.ComponentType<{
    width?: number;
    height?: number;
    color?: Colors;
  }>;
  iconStyle?: ViewStyle;
  shadow?: boolean;
  pending?: boolean;
  disabledTransparent?: boolean;
  rounded?: boolean;
  fullWidth?: boolean;
  forceColorScheme?: ColorSchemeName;
  shakeAnimatedValue?: Animated.SharedValue<number>;
}

const hoverConfig = {
  damping: 25,
  mass: 1,
  stiffness: 400,
};

function Button({
  style,
  to,
  href,
  Icon,
  iconStyle,
  shadow = true,
  size = 'normal',
  pending = false,
  disabledTransparent = false,
  rounded = false,
  fullWidth = true,
  forceColorScheme,
  shakeAnimatedValue,
  ...props
}: Props) {
  const hoverOffset = useSharedValue(0);
  const [isHovered, setIsHovered] = useState(false);
  const hoverStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          scale: interpolate(
            hoverOffset.value,
            [0, 1],
            [1, Platform.OS === 'web' ? 1.015 : 0.985],
          ),
        },
      ],
    }),
    [hoverOffset],
  );

  const type = props.disabled
    ? disabledTransparent
      ? 'disabledTransparent'
      : 'disabled'
    : props.type;

  const gradientStart = useColor(gradients[type][0], forceColorScheme);
  const gradientEnd = useColor(gradients[type][1], forceColorScheme);
  const gradient = [gradientStart, gradientEnd];

  const isTertiary =
    type === ButtonType.tertiary ||
    type === ButtonType.tertiaryDanger ||
    type === ButtonType.tertiaryBold;
  const shadowOnHover =
    !props.disabled &&
    type !== ButtonType.anchor &&
    !isTertiary &&
    (shadow || isHovered);

  const textColor = textColors[type];
  let textType = sizeToTextType[size];

  // If there's a type override
  if (type in buttonTypeToTextTypeOverride) {
    textType = buttonTypeToTextTypeOverride[type][size];
  }

  const { height: _height, paddingHorizontal, icon } = sizeToLayout[size];

  const pressableProps = useLinkProps({ to: to || '' });
  const hrefToUse = to ? pressableProps.href : href;
  const onPress = props.onPress || (to ? pressableProps.onPress : undefined);
  const iconOnly = Icon && !props.children;

  const innerButtonStyle: ViewStyle[] = [buttonStyles.inner];

  const height = iconOnly ? icon.height : _height;
  const width = iconOnly ? icon.width : undefined;
  const borderRadius = iconOnly ? icon.width / 2 : rounded ? height / 2 : 13;

  const outerButtonStyle = [
    buttonStyles.outer,
    {
      height: height,
      width: width,
    },
    style,
  ];

  if (iconOnly) {
    innerButtonStyle.push({
      height,
      width,
    });
    outerButtonStyle.push({ borderRadius: height / 2 });
    innerButtonStyle.push({ borderRadius: height / 2 });
  } else if (rounded) {
    outerButtonStyle.push({ borderRadius: height / 2 });
    innerButtonStyle.push({
      borderRadius: height / 2,
      paddingHorizontal,
      height,
    });
  } else {
    innerButtonStyle.push({
      height,
      paddingHorizontal,
    });
  }

  const shakeAnimatedStyle = useAnimatedStyle(() => {
    if (!shakeAnimatedValue) {
      return {};
    }

    return {
      transform: [
        {
          translateX: interpolate(shakeAnimatedValue.value, [-1, 1], [-4, 4]),
        },
      ],
    };
  }, [shakeAnimatedValue]);

  const content = (
    <>
      <View style={buttonStyles.pendingContainer}>
        {pending && (
          <RefreshControl
            refreshing
            width={24}
            height={24}
            type={props.type}
            disabled={props.disabled}
          />
        )}
      </View>
      {Icon && (
        <View style={[!iconOnly && buttonStyles.iconWithText, iconStyle]}>
          <Icon
            color={textColor}
            width={icon.iconWidth}
            height={icon.iconHeight}
          />
        </View>
      )}
      {props.children && (
        <Text
          type={textType}
          color={textColor}
          forceColorScheme={forceColorScheme}
        >
          {props.children}
        </Text>
      )}
    </>
  );

  let pressable = (
    <Pressable
      {...props}
      onHoverIn={() => {
        hoverOffset.value = withSpring(1, hoverConfig);
        setIsHovered(true);
      }}
      onHoverOut={() => {
        hoverOffset.value = withSpring(0, hoverConfig);
        setIsHovered(false);
      }}
      onPressIn={() => {
        hoverOffset.value = withSpring(1, hoverConfig);
        setIsHovered(true);
      }}
      onPressOut={() => {
        hoverOffset.value = withSpring(0, hoverConfig);
        setIsHovered(false);
      }}
      onPress={onPress}
      href={hrefToUse}
      accessibilityRole="link"
      style={[fullWidth && buttonStyles.pressableFullWidth, shakeAnimatedStyle]}
    >
      {isTertiary ? (
        <View style={buttonStyles.inner}>{content}</View>
      ) : (
        <LinearGradient
          colors={gradient}
          useAngle
          angle={357.62}
          style={innerButtonStyle}
        >
          {content}
        </LinearGradient>
      )}
    </Pressable>
  );

  if (type === 'disabledTransparent') {
    pressable = (
      <BlurView
        amount={4}
        style={[fullWidth && buttonStyles.pressableFullWidth, { borderRadius }]}
      >
        {pressable}
      </BlurView>
    );
  }

  return (
    <ShadowView
      type={ShadowType.smallLight}
      style={outerButtonStyle}
      animatedStyle={hoverStyle}
      enabled={shadowOnHover}
      borderRadius={borderRadius}
    >
      {pressable}
    </ShadowView>
  );
}

const buttonStyles = StyleSheet.create({
  pendingContainer: {
    width: 32,
    marginLeft: -32,
  },
  iconWithText: {
    marginRight: 6,
  },
  pressableFullWidth: {
    width: '100%',
  },
  outer: {
    borderRadius: 13,
    justifyContent: 'center',
  },
  inner: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: '100%',
    borderRadius: 13,
  },
});

const textColors: {
  [type in ButtonType | 'disabled' | 'disabledTransparent']: Colors;
} = {
  primary: 'white',
  secondary: 'LayeredGray',
  secondaryBold: 'Primary',
  secondaryDanger: 'DangerRed',
  tertiary: 'SolidWhite',
  tertiaryBold: 'Primary',
  tertiaryDanger: 'DangerRed',
  disabled: 'LayeredSubtleGray',
  disabledTransparent: 'LayeredSubtleGray',
  anchor: 'Primary',
};

const sizeToTextType: {
  [size in 'xs' | 'small' | 'normal' | 'large']: TextType;
} = {
  xs: TextType.subheadlineEmphasized,
  small: TextType.subheadlineEmphasized,
  normal: TextType.bodyEmphasized,
  large: TextType.bodyEmphasized,
};

const buttonTypeToTextTypeOverride: {
  [type in ButtonType]?: {
    [size in 'xs' | 'small' | 'normal' | 'large']: TextType;
  };
} = {
  anchor: {
    xs: TextType.subheadline,
    small: TextType.subheadline,
    normal: TextType.body,
    large: TextType.body,
  },
  tertiaryDanger: {
    xs: TextType.subheadline,
    small: TextType.subheadline,
    normal: TextType.subheadline,
    large: TextType.body,
  },
  tertiaryBold: {
    xs: TextType.subheadlineEmphasized,
    small: TextType.subheadlineEmphasized,
    normal: TextType.subheadlineEmphasized,
    large: TextType.bodyEmphasized,
  },
  tertiary: {
    xs: TextType.subheadline,
    small: TextType.subheadline,
    normal: TextType.subheadline,
    large: TextType.body,
  },
  secondaryDanger: {
    xs: TextType.subheadline,
    small: TextType.subheadline,
    normal: TextType.body,
    large: TextType.body,
  },
};

const sizeToLayout: {
  [size in 'xs' | 'small' | 'normal' | 'large']: {
    paddingHorizontal: number;
    icon: {
      height: number;
      width: number;
      iconHeight: number;
      iconWidth: number;
    };
    height: number;
  };
} = {
  xs: {
    height: 30,
    paddingHorizontal: 12,
    icon: {
      iconWidth: 11,
      iconHeight: 11,
      width: 30,
      height: 30,
    },
  },
  small: {
    // This is different to small icon buttons. That's by design.
    height: 36,
    paddingHorizontal: 16,
    icon: {
      iconWidth: 14,
      iconHeight: 14,
      width: 40,
      height: 40,
    },
  },
  normal: {
    height: 50,
    paddingHorizontal: 16,
    icon: {
      iconWidth: 18,
      iconHeight: 18,
      width: 50,
      height: 50,
    },
  },
  large: {
    // NOTE: These aren't in the design system yet
    height: 60,
    paddingHorizontal: 20,
    // These are in the system
    icon: {
      iconWidth: 24,
      iconHeight: 24,
      width: 60,
      height: 60,
    },
  },
};

export const gradients: {
  [type in ButtonType | 'disabled' | 'disabledTransparent']: [string, string];
} = {
  primary: ['#178EFC', '#2F9FFC'],
  secondary: ['BackgroundGray', 'BackgroundGray'],
  secondaryBold: ['SolidWhite', 'SolidWhite'],
  secondaryDanger: ['SolidWhite', 'SolidWhite'],

  tertiary: ['transparent', 'transparent'],
  tertiaryBold: ['transparent', 'transparent'],
  tertiaryDanger: ['transparent', 'transparent'],

  disabled: ['BackgroundGray', 'BackgroundGray'],
  disabledTransparent: ['whiteA11', 'whiteA11'],

  anchor: ['transparent', 'transparent'],
};

export default Button;
