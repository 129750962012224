import {
  amber,
  amberDark,
  blue,
  blueDark,
  gold,
  goldDark,
  gray,
  grayA,
  grayDark,
  grayDarkA,
  red,
  redDark,
  whiteA,
  blackA,
  green,
  greenDark,
  sand,
  sandDark,
} from '@radix-ui/colors';

const coolColors = {
  coolBlue: '#5F7EE3',
  coolRed: '#E6534C',
  coolPink: '#EFA7EB',
};

const systemLight = {
  // Exact matches
  Primary: blue.blue9,
  HeadlineBlack: '#000',
  SolidWhite: '#fff',
  SupportingGray: gray.gray11,
  DangerRed: red.red9,
  ActiveAmber: amber.amber9,

  // Customised to match radix
  LayeredGray: grayA.grayA9,
  LayeredSubtleGray: grayA.grayA8,
  LayeredWhite: grayDarkA.grayA12,
  SandyWhite: sand.sand5,

  // Completely custom
  LayeredOffWhite: 'rgba(250, 250, 250, 0.94)',
  DividerGray: 'rgba(60, 60, 67, 0.2)',
  BackgroundGray: 'rgba(118, 118, 128, 0.12)',
};

const systemDark = {
  // Exact matches
  Primary: blue.blue9,
  HeadlineBlack: '#fff',
  SolidWhite: grayDark.gray1,
  SupportingGray: grayDark.gray11,
  DangerRed: redDark.red9,
  ActiveAmber: amberDark.amber9,

  // Customised to match radix
  LayeredGray: grayDarkA.grayA9,
  LayeredSubtleGray: grayDarkA.grayA8,
  LayeredWhite: grayA.grayA12,
  SandyWhite: sandDark.sand5,

  // Completely custom
  LayeredOffWhite: 'rgba(18, 18, 18, 0.94)',
  DividerGray: 'rgba(84, 84, 88, 0.65)',
  BackgroundGray: 'rgba(118, 118, 128, 0.24)',
};

export const light = {
  ...systemLight,
  ...coolColors,
  ...gray,
  ...grayA,
  ...blue,
  ...red,
  ...whiteA,
  ...amber,
  ...gold,
  ...blackA,
  ...green,
  white: '#fff',
  black: '#000',
};

export const dark = {
  ...systemDark,
  ...coolColors,
  ...grayDark,
  ...grayDarkA,
  ...blueDark,
  ...redDark,
  ...whiteA,
  ...amberDark,
  ...goldDark,
  ...blackA,
  ...greenDark,
  white: '#fff',
  black: '#000',
};

export type Colors = keyof typeof light & keyof typeof dark;
