import React, { useCallback, useContext, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';
import Modal from '../Modal';
import SearchSheetContent from './SearchSheetContent';
import SearchInput from './SearchInput';
import Pressable from '../Pressable';
import useExpand from 'app/hooks/use-expand';
import BlurView from '../BlurView';
import StuffListContext from '../StuffList/StuffListContext';

interface Props {
  isMobile: boolean;
}

function SearchSheetModal({ isMobile }: Props) {
  const [modalIsVisible, _setModalIsVisible] = useState(false);

  const {
    parsedQuery,
    onChangeQuery: _onChangeQuery,
    onChangeQueryKey: _onChangeQueryKey,
    resetQuery: _resetQuery,
    username,
    isVisitor,
    containerWidth,
  } = useContext(StuffListContext);

  const {
    open,
    close,
    collapsedStyle,
    expandedStyle,
    transform,
    collapsedRef,
    expandedRef,
  } = useExpand({
    type: 'onTop',
    scaleType: 'dimensions',
  });

  const modalAnimatedValue = useDerivedValue(() => transform.value.progress);

  const collapsedContainerWidth = useMemo(() => {
    // 20 == padding on the content, and then that 4px gutter we have.
    if (isMobile) {
      return containerWidth - 20;
    }

    return (containerWidth - 20) / 3;
  }, [containerWidth, isMobile]);

  const setModalIsVisible = useCallback(
    async (visible: boolean) => {
      if (visible) {
        _setModalIsVisible(true);
        // TODO: CHange this timeout so that it just runs next tick.
        // On firefox, for some reason the expanded wont' be rendered so therefore
        // can't be measured.
        setTimeout(() => {
          open();
        }, 100);
      } else {
        close(() => {
          _setModalIsVisible(false);
        });
      }
    },
    [open, close],
  );

  const onChangeQueryKey = useCallback(
    (key: string, value: string | boolean | undefined) => {
      _onChangeQueryKey(key, value);
      setModalIsVisible(false);
    },
    [_onChangeQueryKey, setModalIsVisible],
  );

  const onChangeQuery = useCallback(
    (_query: { [key: string]: string }, searchId?: string | null) => {
      _onChangeQuery(_query, searchId);
      setModalIsVisible(false);
    },
    [_onChangeQuery, setModalIsVisible],
  );

  const resetQuery = useCallback(() => {
    _resetQuery();
  }, [_resetQuery]);

  const onCancel = useCallback(() => {
    setModalIsVisible(false);
  }, [setModalIsVisible]);

  const contentStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          translateY: transform.value.y / 2,
        },
        {
          scale: interpolate(modalAnimatedValue.value, [0, 1], [0.9, 1]),
        },
      ],
      opacity: interpolate(modalAnimatedValue.value, [0.35, 1], [0, 1]),
    }),
    [modalAnimatedValue, transform],
  );

  const staticValueOn = useSharedValue(1);

  return (
    <>
      <Pressable
        style={[
          styles.searchInputPressable,
          // { opacity: collapsedContainerWidth ? 1 : 0 },
        ]}
        pointerEvents={modalIsVisible ? 'none' : 'auto'}
      >
        <SearchInput
          parsedQuery={parsedQuery}
          onChangeQuery={onChangeQuery}
          onChangeQueryKey={onChangeQueryKey}
          resetQuery={resetQuery}
          username={username}
          isVisitor={isVisitor}
          presentedAnimatedValue={modalAnimatedValue}
          focusedAnimatedValue={staticValueOn}
          showSuggestions={false}
          containerStyle={[collapsedStyle]}
          ref={collapsedRef}
          containerWidth={collapsedContainerWidth}
          inputPointerEvents={modalIsVisible ? 'auto' : 'none'}
          onFocusToggle={(visible) => {
            if (visible && !modalIsVisible) {
              setModalIsVisible(true);
            }
          }}
        />
      </Pressable>
      <Modal
        visible={modalIsVisible}
        onRequestClose={() => setModalIsVisible(false)}
        darkenBackground
        centered
        animatedValue={modalAnimatedValue}
        handleAnimations={false}
        animateContent={false}
        hostName="bottomSheet"
      >
        <View style={[styles.container, isMobile && styles.containerMobile]}>
          <BlurView
            amount={24}
            style={[
              styles.backgroundAbsolute,
              { borderRadius: 20 },
              contentStyle,
            ]}
          />
          <ThemeView
            lightStyle={styles.backgroundLight}
            darkStyle={styles.backgroundDark}
            style={[styles.backgroundAbsolute, contentStyle]}
          />
          <SearchSheetContent
            parsedQuery={parsedQuery}
            onChangeQuery={onChangeQuery}
            onChangeQueryKey={onChangeQueryKey}
            resetQuery={resetQuery}
            isVisitor={isVisitor}
            username={username}
            onCancel={onCancel}
            presentedAnimatedValue={modalAnimatedValue}
            searchInputRef={expandedRef}
            searchInputStyle={[expandedStyle, styles.searchInput]}
            searchPageAnimatedStyle={contentStyle}
            paddingHorizontal={isMobile ? 16 : 32}
            autoFocus
            // As the expanded input in the modal is always in its focused state,
            // the container width, used to center the active query, isn't relevant. By
            // setting it to the collapsed height though, it makes the animation from
            // the expanded state to collapsed smooth and accurate.
            containerWidth={collapsedContainerWidth}
          />
        </View>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    maxWidth: 800,
    width: '100%',
    height: '100%',
    maxHeight: '70vh',
    overflow: 'visible',
    paddingTop: 36,
  },
  containerMobile: {
    paddingHorizontal: 8,
    maxHeight: '96vh',
  },
  backgroundAbsolute: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  ...makeThemeStyle(
    'background',
    {
      borderRadius: 20,
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  searchInputPressable: {
    flex: 1,
    minWidth: 244,
  },
  searchInput: {
    marginBottom: 12,
  },
});

export default SearchSheetModal;
