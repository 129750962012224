import React, { Ref } from 'react';
import { View, ViewProps, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import useStuffsQuery from './useStuffsQuery';
import SearchInputFreeForm from './SearchInputFreeForm';
import SearchInputTopic from './SearchInputTopic';
import SearchInputCollection from './SearchInputCollection';

interface Props {
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  onChangeQueryKey: ReturnType<typeof useStuffsQuery>['onChangeQueryKey'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  onFocusToggle?: (focus: boolean) => void;
  onCancel?: () => void;
  username: string;
  isVisitor: boolean;
  containerStyle?: ViewProps['style'];
  style?: ViewProps['style'];
  suggestionsStyle?: ViewStyle;
  showSuggestions?: boolean;
  onLayout?: ViewProps['onLayout'];
  presentedAnimatedValue: Animated.SharedValue<number>;
  focusedAnimatedValue: Animated.SharedValue<number>;
  showCancel?: boolean;
  suggestionsPortalName?: string;
  autoFocus?: boolean;
  containerWidth?: number;
  inputPointerEvents?: ViewProps['pointerEvents'];
  isBottomSheet?: boolean;

  // To control the search (needed in SearchSheetContent)
  search?: string;
  setSearch?: (search: string) => void;
}

function SearchInput(
  {
    parsedQuery,
    resetQuery,
    onChangeQuery,
    onChangeQueryKey,
    onFocusToggle,
    username,
    isVisitor,
    style,
    containerStyle,
    suggestionsStyle,
    showSuggestions = true,
    presentedAnimatedValue,
    focusedAnimatedValue,
    showCancel,
    containerWidth,
    suggestionsPortalName,
    autoFocus,
    inputPointerEvents,
    isBottomSheet,
    onCancel,
    search,
    setSearch,
  }: Props,
  ref: Ref<View>,
) {
  let content = null;

  if (parsedQuery.topicId) {
    content = (
      <SearchInputTopic
        parsedQuery={parsedQuery}
        resetQuery={resetQuery}
        style={style}
        onFocusToggle={onFocusToggle}
      />
    );
  } else if (parsedQuery.collectionId) {
    content = (
      <SearchInputCollection
        parsedQuery={parsedQuery}
        resetQuery={resetQuery}
        style={style}
        onFocusToggle={onFocusToggle}
        isVisitor={isVisitor}
      />
    );
  } else {
    content = (
      <SearchInputFreeForm
        parsedQuery={parsedQuery}
        onChangeQuery={onChangeQuery}
        onChangeQueryKey={onChangeQueryKey}
        resetQuery={resetQuery}
        presentedAnimatedValue={presentedAnimatedValue}
        focusedAnimatedValue={focusedAnimatedValue}
        username={username}
        isVisitor={isVisitor}
        onFocusToggle={onFocusToggle}
        onCancel={onCancel}
        style={style}
        autoFocus={autoFocus}
        containerWidth={containerWidth}
        search={search}
        setSearch={setSearch}
        showSuggestions={showSuggestions}
        suggestionsPortalName={suggestionsPortalName}
        showCancel={showCancel}
        isBottomSheet={isBottomSheet}
        inputPointerEvents={inputPointerEvents}
        suggestionsStyle={suggestionsStyle}
      />
    );
  }

  return (
    <Animated.View style={containerStyle} ref={ref}>
      {content}
    </Animated.View>
  );
}

export default React.forwardRef(SearchInput);
