import React, { ComponentProps } from 'react';
import Pressable from './Pressable';
import { useColorScheme } from 'react-native';
import EllipsisCircle from './Icons/EllipsisCircle';

interface Props extends ComponentProps<typeof Pressable> {
  width?: number;
  height?: number;
}

const EllipsisButton = ({ width, height, ...props }: Props) => {
  const colorScheme = useColorScheme();

  return (
    <Pressable
      hitSlop={{
        top: 8,
        left: 8,
        right: 8,
        bottom: 8,
      }}
      {...props}
    >
      <EllipsisCircle
        width={width}
        height={height}
        color={colorScheme === 'dark' ? 'gray12' : 'gray9'}
      />
    </Pressable>
  );
};

export default EllipsisButton;
