import { useLinkBuilder, useLinkTo, useRoute } from '@react-navigation/native';
import {
  useInvalidateList,
  useResourceList,
} from 'app/hooks/use-resource/use-resource';
import {
  RefObject,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { LayoutAnimation, Platform } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { useSharedValue, withSpring } from 'react-native-reanimated';
import Routes from '../Navigator/ROUTES';
import { ParsedQuery } from './types';
import { MasonryFlashList } from '@shopify/flash-list';

interface Props {
  username?: string;
  isVisitor: boolean;
  listRef: RefObject<typeof MasonryFlashList>;
}

const fadeConfig = {
  mass: 1,
  stiffness: 300,
  damping: 25,
  restDisplacementThreshold: 0.001,
  restSpeedThreshold: 0.01,
};

const parseQuery = (_query: { [index: string]: string }) => {
  const search = _query['filter[link.search]'];

  const toBool = (str: string) => {
    if (!str) {
      return null;
    }

    return str === 'true' ? true : false;
  };

  const _parsedQuery = {
    search: search || '',
    reactionLove: toBool(_query['filter[link.reaction_love]']),
    reactionEmphasis: toBool(_query['filter[link.reaction_emphasis]']),
    reactionFlag: toBool(_query['filter[link.reaction_flag]']),
    isActive: false,
    isReacted: false,
    topicId: _query['filter[topic.id]'] || null,
    collectionId: _query['filter[collection.id]'] || null,
  };

  if (
    _parsedQuery.reactionLove ||
    _parsedQuery.reactionEmphasis ||
    _parsedQuery.reactionFlag
  ) {
    _parsedQuery.isReacted = true;
  }

  if (
    _parsedQuery.search ||
    _parsedQuery.isReacted ||
    _parsedQuery.topicId ||
    _parsedQuery.collectionId
  ) {
    _parsedQuery.isActive = true;
  }

  return _parsedQuery;
};

const ANIMATE_LINKS_IN = !(
  Platform.OS === 'web' && process.env.NODE_ENV === 'production'
);
const ANIMATION_DURATION = Platform.select({
  web: 350,
  default: 200,
});
const MINIMUM_SPLASH_DURATION = 1000;

function useStuffsQuery({ username, isVisitor, listRef }: Props) {
  const route = useRoute();
  const linkTo = useLinkTo();
  const invalidateSearch = useInvalidateList('search');
  const workingSearchId = useRef<string | null>(null);
  const [_, startTransition] = useTransition();
  const transitionValue = useSharedValue(ANIMATE_LINKS_IN ? 0 : 1);
  const [isRefreshing, setIsRefreshing] = useState(
    ANIMATE_LINKS_IN ? true : false,
  );
  const [hasFinishedFirstLoad, setHasFinishedFirstLoad] = useState(false);
  const timeStuffWasMounted = useRef(new Date().getTime());

  // State

  const getStateFromParams = useCallback(() => {
    const _query: { [index: string]: string } = Object.fromEntries(
      Object.entries(route.params || {}).filter(
        ([key]) => key.startsWith('filter[') || key === 'search_id',
      ),
    );

    if (username && isVisitor) {
      _query['filter[user.id]'] = username;
    }

    _query['page[limit]'] = Platform.OS === 'web' ? '40' : '20';

    return _query;
  }, [route.params, username, isVisitor]);

  // Set the initial query from route params
  const [query, _setQuery] = useState<{
    [index: string]: string;
  }>(getStateFromParams);

  // The query used to fetch links is deferred so that the search UI can
  // update with priority.
  const dataQuery = useDeferredValue(query);

  const {
    stuffList,
    meta: _meta,
    hasMore,
    fetchMore,
    errors,
  } = useResourceList('stuff', {
    include: ['link.link_data.topics', 'tips'],
    query: dataQuery,
    refresh: false,
  });
  const parsedQuery: ParsedQuery = useMemo(() => parseQuery(query), [query]);

  // We manage data separately to support animations between lists
  const [data, setData] = useState<string[]>(stuffList || []);
  const [meta, setMeta] = useState<typeof _meta>(_meta);

  const hasLoaded = useRef(false);

  const fadeIn = useCallback(() => {
    setIsRefreshing(false);

    // if (hasFinishedFirstLoad) {
    // listRef.current?.prepareForLayoutAnimationRender();
    // After removing the item, we can start the animation.
    // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    // }

    setHasFinishedFirstLoad(true);

    transitionValue.value = withSpring(1, fadeConfig);
  }, [transitionValue, hasFinishedFirstLoad]);

  const fadeOut = useCallback(() => {
    transitionValue.value = withSpring(0, fadeConfig);
    setIsRefreshing(true);
  }, [transitionValue]);

  const buildLink = useLinkBuilder();
  const url = useMemo(() => {
    const path = buildLink(Routes.USER, {
      ...route.params,
      username,
    });
    return `https://coolstuff.app${path}`;
  }, [route.params, buildLink, username]);

  // Effects

  useEffect(() => {
    function updateDataList() {
      setData(stuffList || []);
      setMeta(_meta);

      if (stuffList) {
        const timeElapsed = new Date().getTime() - timeStuffWasMounted.current;
        if (
          !isVisitor &&
          Platform.OS !== 'web' &&
          timeElapsed < MINIMUM_SPLASH_DURATION
        ) {
          const delay = MINIMUM_SPLASH_DURATION - timeElapsed;
          setTimeout(fadeIn, delay);
        } else {
          fadeIn();
        }
      } else {
        fadeOut();
      }
    }

    if (!hasLoaded.current || (isVisitor && Platform.OS === 'ios')) {
      // On first load, we want to load straight away
      hasLoaded.current = true;
      updateDataList();
    } else {
      setTimeout(updateDataList, ANIMATION_DURATION);
    }
  }, [stuffList, _meta, fadeIn, fadeOut, isVisitor, listRef]);

  // Update query when the route params change
  useEffect(() => _setQuery(getStateFromParams()), [getStateFromParams]);

  const onChangeQuery = useCallback(
    (_query: { [index: string]: string }, searchId?: string | null) => {
      fadeOut();

      workingSearchId.current = searchId || null;
      const queryIsEmpty = !Object.keys(_query).length;

      // Reset the working ID when the query is reset
      if (queryIsEmpty) {
        workingSearchId.current = null;
      }

      // But if there is an active search, we send that so we don't duplicate the search
      if (workingSearchId.current) {
        _query['search_id'] = workingSearchId.current;
      }

      _setQuery(_query);

      startTransition(() => {
        const params = { ..._query };
        if (username) {
          params.username = username;
        }

        // Update searches
        const shouldInvalidateSearch = !isVisitor;

        if (shouldInvalidateSearch && !queryIsEmpty) {
          setTimeout(() => {
            invalidateSearch();
          }, 3000);
        }

        linkTo({
          screen: route.name,
          params,
        });
      });
    },
    [
      _setQuery,
      linkTo,
      route.name,
      username,
      isVisitor,
      fadeOut,
      invalidateSearch,
    ],
  );

  const onChangeQueryKey = useCallback(
    (key: string, value: string | boolean | undefined) => {
      const newQuery = { ...query };

      if (value === undefined) {
        delete newQuery[key];
      } else {
        newQuery[key] = value.toString();
      }
      onChangeQuery(newQuery);

      if (key.includes('reaction') || !value) {
        ReactNativeHapticFeedback.trigger('impactMedium');
      }
    },
    [query, onChangeQuery],
  );

  const resetQuery = useCallback(() => {
    const _query: {
      [index: string]: string;
    } = {};

    if (username && isVisitor) {
      _query['filter[user.id]'] = username;
    }

    _query['page[limit]'] = Platform.OS === 'web' ? '40' : '20';

    ReactNativeHapticFeedback.trigger('impactMedium');
    onChangeQuery(_query);
  }, [isVisitor, username, onChangeQuery]);

  return {
    parsedQuery,
    query,
    onChangeQueryKey,
    onChangeQuery,
    resetQuery,
    meta,
    hasMore,
    fetchMore,
    data,
    transitionValue,
    isRefreshing,
    hasFinishedFirstLoad,
    url,
    errors,
  };
}

export default useStuffsQuery;
