import { dark, light } from 'app/constants/colors';
import useIsTouchDevice from 'app/hooks/use-is-touch-device';
import {
  useDelete,
  useResource,
  useUpdate,
} from 'app/hooks/use-resource/use-resource';
import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import { LayoutAnimation, Platform, StyleSheet, ViewStyle } from 'react-native';
import { View } from 'react-native';
import Reaction, { ReactionType } from '../Link/Reaction';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import Text, { TextType } from '../Text';
import ThemeView from '../ThemeView';
import BlurView from '../BlurView';
import ContextMenu from '../ContextMenu/ContextMenu';
import Trash from '../Icons/Trash';
import { DropdownItem } from '../Dropdown';
import SearchIcon from '../Icons/Search';
import useCopy from 'app/hooks/use-copy';
import { RNSpringConfig } from '../LayoutAnimation';
import Routes from '../Navigator/ROUTES';
import { Search as SearchType } from 'app/hooks/use-resource/types';
import { useLinkBuilder } from '@react-navigation/native';
import useShareLink from 'app/hooks/use-share-link';
import CopyDoc from '../Icons/CopyDoc';
import PinOutline from '../Icons/PinOutline';
import Share from '../Icons/Share';
import Ellipsis from '../Icons/Ellipsis';
import {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Pressable from '../Pressable';

interface Props {
  searchId: string;
  style?: ViewStyle;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}

const InnerContainer = Platform.select({
  web: BlurView,
  default: View,
});

const hoverConfig = {
  damping: 20,
  mass: 1,
  stiffness: 600,
};

function getQueryFromSearch(search: SearchType) {
  const query: { [key: string]: string } = {};

  const terms = search.attributes.terms;
  const reaction_emphasis = search.attributes.reaction_emphasis;
  const reaction_flag = search.attributes.reaction_flag;
  const reaction_love = search.attributes.reaction_love;

  if (terms.length) {
    query['filter[link.search]'] = search.attributes.terms.join(',');
  }

  if (reaction_emphasis !== null) {
    query['filter[link.reaction_emphasis]'] = String(reaction_emphasis);
  }
  if (reaction_love !== null) {
    query['filter[link.reaction_love]'] = String(reaction_love);
  }
  if (reaction_flag !== null) {
    query['filter[link.reaction_flag]'] = String(reaction_flag);
  }

  return query;
}

function Search({ searchId, style, onChangeQuery }: Props) {
  const { ready, search } = useResource('search', searchId);
  const copy = useCopy(['searchSheetItemName', 'homeSearchCopiedToClipboard']);

  const updateSearch = useUpdate('search');
  const deleteSearch = useDelete('search');
  const isTouchDevice = useIsTouchDevice();
  const isHoveredValue = useSharedValue(isTouchDevice ? 1 : 0);
  const buildLink = useLinkBuilder();
  const shareLink = useShareLink();

  const ellipsisStyle = useAnimatedStyle(
    () => ({
      opacity: isHoveredValue.value,
    }),
    [isHoveredValue],
  );

  const onSearch = useCallback(() => {
    if (!search) {
      return;
    }

    const query = getQueryFromSearch(search);
    // TODO: This is probably relevant
    // const shouldUpdateSearch = !search.attributes.pinned;

    onChangeQuery(query, search.id);
  }, [search, onChangeQuery]);

  const togglePinned = useCallback(() => {
    LayoutAnimation.configureNext(RNSpringConfig);

    if (!search) {
      return null;
    }

    updateSearch(
      search.id,
      {
        id: search.id,
        type: 'search',
        attributes: {
          pinned: !search.attributes.pinned,
        },
      },
      { invalidateLists: ['explorable'] },
    );
  }, [search, updateSearch]);

  const removeSearch = useCallback(() => {
    LayoutAnimation.configureNext(RNSpringConfig);
    deleteSearch(searchId);
  }, [deleteSearch, searchId]);

  const onShare = useCallback(
    (tryNative = true) => {
      if (!search) {
        return;
      }
      const path = buildLink(Routes.USER, {
        ...getQueryFromSearch(search),
      });
      const url = `https://coolstuff.app${path}`;
      shareLink({
        url,
        shareConfirmation: copy.homeSearchCopiedToClipboard,
        tryNative,
      });
    },
    [buildLink, search, copy.homeSearchCopiedToClipboard, shareLink],
  );

  if (!ready) {
    return null;
  }

  return (
    <ContextMenu
      actions={[
        <DropdownItem
          label="Search"
          Icon={SearchIcon}
          onPress={onSearch}
          key="search"
        />,
        <DropdownItem
          label="Share"
          Icon={Share}
          onPress={onShare}
          key="share"
        />,
        <DropdownItem
          label="Copy"
          Icon={CopyDoc}
          onPress={() => onShare(false)}
          key="copy"
        />,
        <DropdownItem
          label={search.attributes.pinned ? 'Unpin' : 'Pin'}
          Icon={PinOutline}
          onPress={togglePinned}
          key="pin"
        />,
        <DropdownItem
          label="Remove"
          renderIcon={(isActive) =>
            isActive ? <Trash color="white" /> : <Trash color="red9" />
          }
          last
          textColor="red9"
          activeTextColor="white"
          activeBackgroundColor="red9"
          onPress={removeSearch}
          key="remove"
        />,
      ]}
      onPress={onSearch}
      onHoverIn={() => (isHoveredValue.value = withSpring(1, hoverConfig))}
      onHoverOut={(event) => {
        if (event.type !== 'pointerleave') {
          return;
        }

        isHoveredValue.value = withSpring(0, hoverConfig);
      }}
    >
      {({ open }) => (
        <ThemeView
          darkStyle={styles.containerDark}
          lightStyle={styles.containerLight}
          style={[styles.container, style]}
        >
          <InnerContainer amount={24} style={styles.innerContainer}>
            <View style={styles.content}>
              <View style={styles.topRow}>
                {search.attributes.terms.map((term) => (
                  <Text
                    key={term}
                    style={styles.term}
                    type={TextType.body}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {term}
                  </Text>
                ))}
                {[
                  ReactionType.reactionLove,
                  ReactionType.reactionFlag,
                  ReactionType.reactionEmphasis,
                ]
                  .filter((reactionType) => search.attributes[reactionType])
                  .map((reactionType) => (
                    <Reaction
                      style={styles.reaction}
                      type={reactionType}
                      selected
                      key={reactionType}
                      transparentBackground
                    />
                  ))}
              </View>
              <Text type={TextType.caption1} color="SupportingGray">
                {search.attributes.link_count}{' '}
                {pluralize(
                  copy.searchSheetItemName,
                  search.attributes.link_count,
                )}
              </Text>
            </View>
            <Pressable onPress={open} style={[styles.ellipsis, ellipsisStyle]}>
              <Ellipsis />
            </Pressable>
          </InnerContainer>
        </ThemeView>
      )}
    </ContextMenu>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
  },
  containerLight: {
    backgroundColor: light.SolidWhite,
  },
  containerDark: {
    backgroundColor: dark.gray4,
  },
  innerContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 15,
    height: 80,
    gap: 12,
  },
  content: {
    gap: 2,
    flex: 1,
  },
  topRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 8,
    // Height of reaction (so that rows without reactions are the same height)
    height: 28,
  },
  term: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ellipsis: {
    padding: 8,
    margin: -8,
  },
  removeButton: {
    position: 'absolute',
    top: -12,
    left: -12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  reaction: {
    marginRight: 4,
    width: 28,
    height: 28,
  },
});

export default React.memo(Search);
