import { Colors } from 'app/constants/colors';
import useColor from 'app/hooks/use-color';
import * as React from 'react';
import Svg, { Mask, Path } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color: Colors;
}

export default function PinOutline({ color, width = 20, height = 20 }: Props) {
  const colorToUse = useColor(color);

  return (
    <Svg width={width} height={height} viewBox="0 0 20 20" fill="none">
      <Path
        d="M18.9944 7.6949L18.9916 7.69777L13.5026 13.1951L13.1463 13.5519L13.3145 14.0272C13.6529 14.9837 13.8587 16.6923 12.1914 18.917C12.1293 18.9986 12.0508 19.0661 11.9611 19.1153C11.8785 19.1605 11.7881 19.1892 11.6948 19.2H11.6315C11.4454 19.1989 11.267 19.1247 11.1348 18.9929C11.1346 18.9927 11.1344 18.9925 11.1342 18.9923L6.61248 14.4637L5.55222 13.4018L1.01092 8.85362L1.01093 8.8536L1.00671 8.84944C0.936788 8.78044 0.882236 8.69726 0.846748 8.60538C0.811258 8.5135 0.795665 8.41507 0.801034 8.31664C0.806402 8.2182 0.832605 8.12208 0.877856 8.03466C0.923106 7.94724 0.986346 7.87055 1.06331 7.80962L1.06334 7.80966L1.06968 7.80453C3.17294 6.10444 5.14395 6.45194 5.99792 6.71571L6.45909 6.85816L6.80013 6.5166L12.3054 1.00298C12.4383 0.872754 12.6165 0.8 12.802 0.8C12.9875 0.8 13.1658 0.872756 13.2986 1.00299C13.2992 1.00355 13.2998 1.00411 13.3004 1.00468L18.9915 6.70455L18.9944 6.70741C19.0595 6.77191 19.1112 6.84874 19.1465 6.9335C19.1818 7.01826 19.2 7.10924 19.2 7.20116C19.2 7.29308 19.1818 7.38406 19.1465 7.46882C19.1112 7.55358 19.0595 7.63041 18.9944 7.6949Z"
        stroke={colorToUse}
        strokeWidth="1.6"
      />
      <Mask id="Path-2-inside-1_2390_34779" fill="white">
        <Path d="M2.81897 18.2447L6.03729 15.0121L4.97703 13.9502L1.74932 17.1734C1.60837 17.3159 1.5293 17.5084 1.5293 17.709C1.5293 17.9096 1.60837 18.1021 1.74932 18.2447C1.8928 18.3836 2.08457 18.4613 2.28415 18.4613C2.48373 18.4613 2.67549 18.3836 2.81897 18.2447Z" />
      </Mask>
      <Path
        d="M6.03729 15.0121L7.17116 16.1409L8.29667 15.0104L7.16952 13.8816L6.03729 15.0121ZM2.81897 18.2447L3.93204 19.3941L3.94253 19.3839L3.95284 19.3735L2.81897 18.2447ZM1.74932 18.2447L0.611681 19.3697L0.623838 19.382L0.636258 19.3941L1.74932 18.2447ZM1.74932 17.1734L0.61872 16.0412L0.611682 16.0483L1.74932 17.1734ZM4.97703 13.9502L6.10926 12.8197L4.97867 11.6874L3.84644 12.818L4.97703 13.9502ZM4.90341 13.8832L1.6851 17.1158L3.95284 19.3735L7.17116 16.1409L4.90341 13.8832ZM1.7059 17.0953C1.86062 16.9455 2.06792 16.8613 2.28415 16.8613V20.0613C2.89954 20.0613 3.49037 19.8218 3.93204 19.3941L1.7059 17.0953ZM2.28415 16.8613C2.50038 16.8613 2.70767 16.9455 2.86239 17.0953L0.636258 19.3941C1.07793 19.8218 1.66876 20.0613 2.28415 20.0613V16.8613ZM2.88697 17.1196C3.04248 17.2769 3.1293 17.4887 3.1293 17.709H-0.0707031C-0.0707031 18.3306 0.174261 18.9274 0.611681 19.3697L2.88697 17.1196ZM3.1293 17.709C3.1293 17.9293 3.04248 18.1412 2.88697 18.2985L0.611682 16.0483C0.17426 16.4906 -0.0707031 17.0875 -0.0707031 17.709H3.1293ZM2.87991 18.3056L6.10761 15.0824L3.84644 12.818L0.618742 16.0412L2.87991 18.3056ZM7.16952 13.8816L6.10926 12.8197L3.84479 15.0807L4.90506 16.1426L7.16952 13.8816Z"
        fill={colorToUse}
        mask="url(#Path-2-inside-1_2390_34779)"
      />
    </Svg>
  );
}
