import useColor from 'app/hooks/use-color';
import shadows from 'app/styles/shadows';
import { useMemo } from 'react';
import Animated, {
  Extrapolation,
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
} from 'react-native-reanimated';
import useStuffsQuery from './useStuffsQuery';
import { Platform, ViewStyle } from 'react-native';
import { dark } from 'app/constants/colors';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props {
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  presentedAnimatedValue: Animated.SharedValue<number>;
  focusedAnimatedValue: Animated.SharedValue<number>;
  textWidth: number;
  containerWidth: number;
  showCancel?: boolean;
}

const DEBUG_ANIMATIONS = false;

export const animationConfig = Platform.select({
  ios: {
    stiffness: 300,
    mass: 1,
    damping: 25,
    restDisplacementThreshold: 0.05,
    restSpeedThreshold: 0.01,
  },
  default: {
    stiffness: 1000,
    mass: DEBUG_ANIMATIONS ? 100 : 1,
    damping: DEBUG_ANIMATIONS ? 300 : 100,
    restDisplacementThreshold: 0.001,
    restSpeedThreshold: 0.01,
  },
});

const leftElementLeftPadding = 4;
export const SEARCH_ICON_WIDTH = 16;
const leftElementWidth = SEARCH_ICON_WIDTH + leftElementLeftPadding;
const reactionsContainerWidth = 88;
const reactionWidth = 28;
const clearButtonWidth = 28;
const searchInputPadding = 6;

const useElementDimensions = ({
  textWidth: measuredTextWidth,
  containerWidth,
  parsedQuery,
}: Pick<Props, 'parsedQuery' | 'containerWidth' | 'textWidth'>) => {
  const reactionsWidth = useMemo(() => {
    const reactionsCount =
      Number(parsedQuery.reactionFlag) +
      Number(parsedQuery.reactionLove) +
      Number(parsedQuery.reactionEmphasis);
    return reactionWidth * reactionsCount;
  }, [parsedQuery]);

  const emptySpace = useMemo(() => {
    let textWidth = measuredTextWidth;

    // When there's an active query but its only reactions, we ignore
    // the text.
    if (parsedQuery.isActive && !parsedQuery.search) {
      textWidth = 0;
    }

    // Container - padding applied to the container
    // NOTE: This is the value that comes from `useLayoutInfo` `contentPaddingHorizontal`. The
    // reason we're not using context is that the stuff list can be rendered in a portal outside
    // of the provider. When I refactor all of this to use context rather than props, I can fix it then.
    const textInputContainerWidth = containerWidth - 12;

    // Available space is the container - the width of the text
    const space = textInputContainerWidth - textWidth;

    return space;
  }, [
    measuredTextWidth,
    parsedQuery.isActive,
    parsedQuery.search,
    containerWidth,
  ]);

  const leftElementActiveWidth = useMemo(() => {
    // Split between each side
    let width = emptySpace / 2;

    // If there's not enoough space for the reactions width on both sides
    // we start left-aligning the text by reducing the left element margin.
    if (width < reactionsWidth) {
      width = emptySpace - reactionsWidth;
    }

    // Subtract half of the reactions width so that the reactions + text
    // are centered.
    width -= reactionsWidth / 2;

    return Math.max(width, 12);
  }, [emptySpace, reactionsWidth]);

  const rightElementActiveWidth = useMemo(() => {
    // We split between each side, then add the half the reactions width so that
    // its equal on both sides. But the minimum is the reactions width as always
    // need those showing.
    let width = Math.max(emptySpace / 2 + reactionsWidth / 2, reactionsWidth);

    // If the width given to the right element cannot fit both the reactions
    // and the clear button, increase the width to allow the button space.
    // NOTE: There's some manual tweaking here of the numbers. It could probably
    // be more accurate...
    if (width < reactionsWidth + clearButtonWidth - 14) {
      width += 20;
    }

    return width;
  }, [emptySpace, reactionsWidth]);

  return {
    leftElementActiveWidth,
    rightElementActiveWidth,
  };
};

function useSearchInputStyles({
  parsedQuery,
  presentedAnimatedValue,
  focusedAnimatedValue,
  showCancel = true,
  textWidth,
  containerWidth,
}: Props) {
  const { leftElementActiveWidth, rightElementActiveWidth } =
    useElementDimensions({
      textWidth,
      containerWidth,
      parsedQuery,
    });
  const colorScheme = useColorScheme();

  // Before the text has been measuered on first mount, we need hide the text input and the left
  // element to avoid weird layout issues. Ideally we'd have a solution that meant it could render
  // immediately, but that would require a programatic way to measure text. Or at least a method
  // that centered text without needing to measure (which would then need to seemlessly transition
  // into a centralization accomplished via sizing of the elements).
  const textIsMeasured = textWidth > 0;

  const isActiveValue = useDerivedValue(() => {
    if (parsedQuery.isActive) {
      return (
        interpolate(
          focusedAnimatedValue.value,
          [0, 1],
          [1, 0],
          Extrapolation.CLAMP,
        ) + interpolate(presentedAnimatedValue.value, [0, 1], [1, 0])
      );
    }

    return 0;
  }, [parsedQuery.isActive, focusedAnimatedValue, presentedAnimatedValue]);

  const isCenteredValue = useDerivedValue(() => {
    if (parsedQuery.isActive) {
      return isActiveValue.value;
    }

    return interpolate(presentedAnimatedValue.value, [0, 1], [1, 0]);
  }, [parsedQuery.isActive, presentedAnimatedValue, isActiveValue]);

  const isCancelVisibleValue = useDerivedValue(
    () =>
      (showCancel ? 1 : 0) *
      focusedAnimatedValue.value *
      presentedAnimatedValue.value,
    [showCancel, focusedAnimatedValue, presentedAnimatedValue],
  );

  const leftElementAnimatedStyle = useAnimatedStyle(() => {
    /*
     * Interpolates between two widths to cause the text input to center.
     * Fades to 0 when input is active.
     * Adds padding to the right so text input doesn't touch it. Includes that
     * in the width too if necessary.
     */
    const width = interpolate(
      isCenteredValue.value,
      [0, 1],
      [leftElementWidth + searchInputPadding, leftElementActiveWidth],
      Extrapolation.CLAMP,
    );

    return {
      paddingRight: searchInputPadding,
      opacity: textIsMeasured
        ? interpolate(isActiveValue.value, [0, 1], [1, 0])
        : 0,
      minWidth: width,
      maxWidth: width,
    };
  }, [isCenteredValue, isActiveValue, leftElementActiveWidth, textIsMeasured]);

  const rightElementAnimatedStyle = useAnimatedStyle(() => {
    /*
     * Interpolates between two widths to cause the text input to center.
     * Includes padding to the left when the input is not centered, so that
     * the text input doesn't touch the right element.
     */
    const width = interpolate(
      isCenteredValue.value,
      [0, 1],
      [reactionsContainerWidth + searchInputPadding, rightElementActiveWidth],
      Extrapolation.CLAMP,
    );

    return {
      paddingLeft: interpolate(
        isCenteredValue.value,
        [0, 1],
        [searchInputPadding, 0],
        Extrapolation.CLAMP,
      ),
      minWidth: width,
      maxWidth: width,
    };
  }, [isCenteredValue, rightElementActiveWidth]);

  const animatedInputStyle = useAnimatedStyle(() => {
    if (!parsedQuery.isActive) {
      return {
        opacity: textIsMeasured ? 1 : 0,
      };
    }

    // Fades the search input when the query is active, but without any
    // search term, just reactions.
    return {
      opacity: parsedQuery.search
        ? 1
        : interpolate(isActiveValue.value, [0, 1], [1, 0]) *
          presentedAnimatedValue.value,
    };
  }, [
    isActiveValue,
    presentedAnimatedValue,
    parsedQuery.isActive,
    parsedQuery.search,
    textIsMeasured,
  ]);

  const cancelStyle = useAnimatedStyle(() => {
    let transform = [];

    if (Platform.OS !== 'web') {
      transform = [
        {
          translateX: interpolate(isCancelVisibleValue.value, [0, 1], [68, 0]),
        },
      ];
    } else {
      // On web, if the cancel transitions while the sheet is presented, it means
      // we cleared the filter but remained opened. In those cases, we want the
      // cancel to just ease in from the right.
      if (presentedAnimatedValue.value === 1) {
        transform = [
          {
            translateX: interpolate(
              isCancelVisibleValue.value,
              [0, 1],
              [40, 0],
            ),
          },
        ];
      } else {
        // Whereas when we aren't presented, we want to animate with the
        // expanding sheet.
        transform = [
          {
            translateX: interpolate(
              isCancelVisibleValue.value,
              [0, 1],
              [-68, 0],
            ),
          },
          {
            translateY: interpolate(
              isCancelVisibleValue.value,
              [0, 1],
              [-68, 0],
            ),
          },
        ];
      }
    }

    return {
      position: 'absolute',
      right: 0,
      top: 9,
      opacity: isCancelVisibleValue.value,
      transform,
    };
  }, [isCancelVisibleValue, presentedAnimatedValue]);

  const inactiveColor = useColor('BackgroundGray');
  const activeColor = useColor(
    Platform.OS === 'web'
      ? 'BackgroundGray'
      : colorScheme === 'dark'
      ? dark.gray8
      : 'SolidWhite',
  );
  const searchInputContainerStyle = useAnimatedStyle(() => {
    return {
      marginRight: interpolate(isCancelVisibleValue.value, [0, 1], [0, 64]),
      backgroundColor: interpolateColor(
        isActiveValue.value,
        [0, 1],
        [inactiveColor, activeColor],
      ),
      ...shadows.bigInner,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: Platform.OS === 'web' ? 0 : isActiveValue.value,
    };
  }, [isCancelVisibleValue, isActiveValue, inactiveColor, activeColor]);

  const clearButtonStyle = useAnimatedStyle(() => {
    return {
      opacity: isActiveValue.value,
      display: isActiveValue.value ? 'flex' : 'none',
    };
  }, [isActiveValue]);

  const reactionsContainerStyle = useAnimatedStyle(() => {
    const style: ViewStyle = {
      opacity: parsedQuery.isReacted
        ? 1
        : interpolate(
            isCenteredValue.value,
            [0, 1],
            [1, 0],
            Extrapolation.CLAMP,
          ),
    };

    // On web we have to handle pointerEvents through style
    // if (Platform.OS === 'web') {
    const reactionsAreTappable =
      presentedAnimatedValue &&
      presentedAnimatedValue.value === 1 &&
      isActiveValue.value === 0;

    style.pointerEvents = reactionsAreTappable ? 'auto' : 'none';

    return style;
  }, [
    presentedAnimatedValue,
    parsedQuery.isReacted,
    isActiveValue,
    isCenteredValue,
  ]);

  const heartStyle = useAnimatedStyle(() => {
    if (!parsedQuery.reactionLove) {
      return {
        opacity: interpolate(isCenteredValue.value, [0, 1], [1, 0]),
      };
    }

    return {
      opacity: 1,
    };
  }, [isCenteredValue, parsedQuery.reactionLove]);

  const flagStyle = useAnimatedStyle(() => {
    if (parsedQuery.reactionFlag) {
      if (!parsedQuery.search) {
        const love = parsedQuery.reactionLove;
        let shift = 0;

        if (!love) {
          shift = -1;
        }

        return {
          opacity: 1,
          transform: [
            {
              translateX: interpolate(
                isCenteredValue.value,
                [0, 1],
                [0, reactionWidth * shift],
                Extrapolation.CLAMP,
              ),
            },
          ],
        };
      }

      let shift = parsedQuery.reactionLove ? 0 : -1;

      return {
        opacity: 1,
        transform: [
          {
            translateX: interpolate(
              isCenteredValue.value,
              [0, 1],
              [0, reactionWidth * shift],
              Extrapolation.CLAMP,
            ),
          },
        ],
      };
    }

    return {
      opacity: interpolate(isActiveValue.value, [0, 1], [1, 0]),
      // Required to ensure the translation is removed if this reaction is removed
      transform: [{ translateX: 0 }],
    };
  }, [
    isActiveValue,
    isCenteredValue,
    parsedQuery.reactionFlag,
    parsedQuery.reactionLove,
    parsedQuery.search,
  ]);

  const emphasisStyle = useAnimatedStyle(() => {
    if (parsedQuery.reactionEmphasis) {
      if (!parsedQuery.search) {
        // Can move -1, -1/2
        // Only reaction -> -1
        // Thhere's one more -> -0.5
        // There's all of them -> don't move
        const love = parsedQuery.reactionLove;
        const flag = parsedQuery.reactionFlag;

        let shift = 0;

        if (!love && !flag) {
          shift = -2;
        } else if ((love || flag) && !(love && flag)) {
          shift = -1;
        }

        return {
          opacity: 1,
          transform: [
            {
              translateX: interpolate(
                isCenteredValue.value,
                [0, 1],
                [0, reactionWidth * shift],
                Extrapolation.CLAMP,
              ),
            },
          ],
        };
      }

      let leftShifts = 0;
      if (!parsedQuery.reactionLove && !parsedQuery.reactionFlag) {
        leftShifts = 2;
      } else if (!parsedQuery.reactionLove || !parsedQuery.reactionFlag) {
        leftShifts = 1;
      }

      return {
        opacity: 1,
        transform: [
          {
            translateX: interpolate(
              isCenteredValue.value,
              [0, 1],
              [0, -reactionWidth * leftShifts],
              Extrapolation.CLAMP,
            ),
          },
        ],
      };
    }

    return {
      opacity: interpolate(isActiveValue.value, [0, 1], [1, 0]),
      // Required to ensure the translation is removed if this reaction is removed
      transform: [{ translateX: 0 }],
    };
  }, [
    isActiveValue,
    isCenteredValue,
    parsedQuery.reactionEmphasis,
    parsedQuery.reactionLove,
    parsedQuery.reactionFlag,
    parsedQuery.search,
  ]);

  return {
    leftElementAnimatedStyle,
    rightElementAnimatedStyle,
    searchInputContainerStyle,
    clearButtonStyle,
    heartStyle,
    flagStyle,
    emphasisStyle,
    animatedInputStyle,
    cancelStyle,
    reactionsContainerStyle,
  };
}

export default useSearchInputStyles;
