import { useCallback, useState } from 'react';
import { Gesture } from 'react-native-gesture-handler';
import { runOnJS, useSharedValue, withSpring } from 'react-native-reanimated';
import { ViewState } from './useLayoutInfo';
import ReactNativeHapticFeedback, {
  HapticFeedbackTypes,
} from 'react-native-haptic-feedback';

const SNAP_DISTANCE_DOWN = 0.7;
const SNAP_DISTANCE_UP = 1.3;

function useViewState({ username }: { username: string }) {
  const viewStateAnimatedScale = useSharedValue(1);
  const [viewState, setViewState] = useState(ViewState.regular);
  const snappedUp = useSharedValue(0);
  const snappedDown = useSharedValue(0);

  const triggerHaptics = useCallback(
    (type: HapticFeedbackTypes = 'impactLight') =>
      ReactNativeHapticFeedback.trigger(type),
    [],
  );

  const pinchGesture = Gesture.Pinch()
    .enabled(username === 'ben' || username == 'victor')
    .onUpdate((e) => {
      viewStateAnimatedScale.value = e.scale;

      const scale = e.scale;

      if (scale < SNAP_DISTANCE_DOWN && snappedUp.value === 0) {
        snappedUp.value = 1;
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        scale < 1 &&
        scale >= SNAP_DISTANCE_DOWN &&
        snappedUp.value === 1
      ) {
        snappedUp.value = 0;
        // runOnJS(triggerHaptics)('impactLight');
      } else if (scale > SNAP_DISTANCE_UP && snappedDown.value === 0) {
        snappedDown.value = 1;
        runOnJS(triggerHaptics)('impactLight');
      } else if (
        scale > 1 &&
        scale <= SNAP_DISTANCE_UP &&
        snappedDown.value === 1
      ) {
        snappedDown.value = 0;
        // runOnJS(triggerHaptics)('impactLight');
      }
    })
    .onEnd((e) => {
      if (e.scale < 0.8) {
        runOnJS(setViewState)(ViewState.small);
      } else if (e.scale > 1.2) {
        runOnJS(setViewState)(ViewState.regular);
      }

      viewStateAnimatedScale.value = withSpring(1);
      // listRef.current?.prepareForLayoutAnimationRender();
      // // After removing the item, we can start the animation.
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    });

  return {
    pinchGesture,
    viewState,
    viewStateAnimatedScale,
  };
}

export default useViewState;
