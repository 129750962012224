import Link from 'app/components/Link';
import useIsMobile from 'app/hooks/use-is-mobile';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import { MasonryFlashList } from '@shopify/flash-list';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Header from './Header';
import useLayoutInfo, { LINK_PADDING, ViewState } from './useLayoutInfo';
import {
  useInvalidateList,
  useResourceList,
} from 'app/hooks/use-resource/use-resource';
import { LinkDataSkeleton } from '../LinkData/LinkData';
import SearchSheetBottom from '../SearchSheet/SearchSheetBottom';
import { StuffTips } from '../Tips';
import useWindowDimensions from 'app/hooks/use-window-dimensions';
import useNavigationOptions from './useNavigationOptions';
import Footer from './Footer';
import StuffListContext, { StuffListContextShape } from './StuffListContext';
import Splash from './Splash';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import LinkExpandedContext from './LinkExpandedContext';
import StuffListAction, { actionButtonAnimatedConfig } from './StuffListAction';
import StatusBarBlurHeader from '../StatusBarBlurHeader';
import { GestureDetector } from 'react-native-gesture-handler';
import useViewState from './useViewState';
import useColorScheme from 'app/hooks/use-color-scheme';
import Selectable from './Selectable';
import SelectToolbar from './SelectToolbar';
import useSelectLinks from './useSelectLinks';

const AnimatedMasonaryFlashList =
  Animated.createAnimatedComponent(MasonryFlashList);

interface Props {
  username: string;
  displayName: string;
  ogGraphImage?: string;
  isVisitor: boolean;
  isPublic: boolean;
}

const ResourcePreloader = ({ username }: { username: string }) => {
  useResourceList('explorable', {
    include: [
      'searches',
      'topics',
      'collections',
      'explorables.smart_search',
      'explorables.collection',
    ],
    query: {
      'filter[user.id]': username,
    },
  });

  return null;
};

// const skeletons = range(20).map((_) => 'SKELETON');

const StuffList = ({
  username,
  displayName,
  ogGraphImage,
  isVisitor,
  isPublic,
}: Props) => {
  const isMobile = useIsMobile();
  const containerRef = useRef<typeof View>(null);
  const listRef = useRef<typeof MasonryFlashList>(null);
  const isIOS = Platform.OS === 'ios';
  const colorScheme = useColorScheme();
  const showSearchSheet = isIOS && isMobile && !isVisitor;
  const scrollViewOffset = useSharedValue(0);
  const presentedAnimatedValue = useSharedValue<number>(0);
  const stuffVisibleAnimatedValue = useSharedValue<number>(
    Platform.OS === 'web' ? 1 : 0,
  );

  const windowDimenions = useWindowDimensions();
  const invalidateList = useInvalidateList('stuff');
  const insets = useSafeAreaInsets();
  const { linkIsExpanded } = useContext(LinkExpandedContext);
  const { selectedLinkIds, setSelectedLinkIds, isSelecting, setIsSelecting } =
    useSelectLinks();

  const {
    onChangeQuery,
    onChangeQueryKey,
    resetQuery,
    parsedQuery,
    data,
    transitionValue,
    meta,
    hasMore,
    fetchMore,
    isRefreshing,
    hasFinishedFirstLoad,
    url,
    errors,
  } = useStuffsQuery({ username, isVisitor, listRef });

  useNavigationOptions({
    displayName,
    ogGraphImage,
    parsedQuery,
  });

  const { viewState, viewStateAnimatedScale, pinchGesture } = useViewState({
    username,
  });

  // Layout calculations
  const {
    columns,
    columnWidth,
    contentPaddingVertical,
    contentPaddingHorizontal,
    contentInnerPaddingHorizontal,
    containerWidth,
    estimatedListSize,
    overrideItemLayout,
    headerVisible,
  } = useLayoutInfo({
    parsedQuery,
    isVisitor,
    viewState,
  });

  const [didPullToRefresh, setDidPullToRefresh] = useState(false);
  const isRefeshingRef = useRef(false);
  const onRefresh = useCallback(() => {
    setDidPullToRefresh(true);
    invalidateList();
    isRefeshingRef.current = true;
  }, [invalidateList]);

  useEffect(() => {
    setDidPullToRefresh(false);
    isRefeshingRef.current = false;
  }, [data, errors]);

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollViewOffset.value = event.contentOffset.y;
  });

  const onEndReached = useCallback(() => {
    if (hasMore) {
      // On iOS, we flex: 1 the scrollview, so the offset is set.
      if (Platform.OS !== 'web' && !scrollViewOffset.value) {
        return;
      } else if (Platform.OS === 'web' && !window.scrollY) {
        return;
      }

      fetchMore();
    }
  }, [fetchMore, hasMore, scrollViewOffset]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return;
    }

    const onScroll = () => {
      if (!containerRef.current) {
        return null;
      }

      const endY = containerRef.current.clientHeight - windowDimenions.height;
      const threshold = endY - windowDimenions.height;

      if (window.scrollY > threshold && !isRefeshingRef.current) {
        isRefeshingRef.current = true;
        onEndReached();
      }
    };

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [onEndReached, windowDimenions.height]);

  // Style

  const perspective = 850;

  const cellRendererStyle = useAnimatedStyle(() => {
    const style = {
      opacity: transitionValue.value,
      transform: [
        { perspective },
        {
          scale: interpolate(
            stuffVisibleAnimatedValue.value,
            [0, 1],
            [perspective / (perspective - 30), 1],
          ),
        },
        {
          scale: interpolate(
            viewStateAnimatedScale.value,
            [0, 1, 2],
            [0.9, 1, 1.1],
            Extrapolation.CLAMP,
          ),
        },
        {
          translateY: interpolate(
            stuffVisibleAnimatedValue.value,
            [0, 1],
            [20, 0],
          ),
        },
      ],
    };

    if (Platform.OS !== 'web') {
      style.transform.push({
        scale: interpolate(transitionValue.value, [0, 1], [0.97, 1]),
      });
    }

    return style;
  }, [transitionValue, stuffVisibleAnimatedValue, viewStateAnimatedScale]);

  useEffect(() => {
    if (hasFinishedFirstLoad) {
      stuffVisibleAnimatedValue.value = withSpring(1, {
        mass: 1,
        stiffness: 200,
        damping: 30,
      });
    }
  }, [hasFinishedFirstLoad, stuffVisibleAnimatedValue]);

  useEffect(() => {
    if (!isRefreshing && listRef.current) {
      // The inset from the large title is the statusBar + 96
      // See:
      listRef.current.scrollToOffset({ offset: insets.top });
    }
  }, [isRefreshing, insets.top]);

  useEffect(() => {
    function updatePresentedValue() {
      presentedAnimatedValue.value = withSpring(
        linkIsExpanded ? -1 : 0,
        actionButtonAnimatedConfig,
      );
    }

    if (Platform.OS === 'web') {
      updatePresentedValue();
    } else if (isVisitor) {
      // On iOS and isVisitor, the page is presented modally, which means
      // we can't render the actions in a portal. The links are still rendered
      // in a portal, so they'll clip the action buttons during their animation.
      // To avoid this, we simply delay when the buttons animate back in after
      // the link expansion has finished.
      setTimeout(updatePresentedValue, 500);
    }
  }, [linkIsExpanded, presentedAnimatedValue, isVisitor]);

  // Render components
  const renderItem = ({ item: stuffId }: { item: unknown }) => {
    if (typeof stuffId !== 'string') {
      return null;
    }

    if (columnWidth === 0) {
      return null;
    }

    if (stuffId === 'SKELETON') {
      return <LinkDataSkeleton width={columnWidth} style={styles.cell} />;
    }

    // NOTE: In the future, if we add stuff types without a identifable ID, we'll
    // needc to consult the entities ref.
    if (stuffId.startsWith('tips')) {
      if (viewState !== ViewState.regular) {
        return null;
      }

      return (
        <StuffTips
          columnWidth={columnWidth}
          style={[styles.cell, cellRendererStyle]}
          stuffId={stuffId}
        />
      );
    }

    const linkId = stuffId as string;

    return (
      <Selectable linkId={linkId}>
        <Link
          linkId={linkId}
          style={[cellRendererStyle, styles.cell]}
          width={columnWidth}
          isVisitor={isVisitor}
          scrollViewOffset={scrollViewOffset}
          windowDimensions={windowDimenions}
        />
      </Selectable>
    );
  };

  const getItemType = useCallback((item: unknown) => {
    if (item === 'tips') {
      return 'tips';
    }

    return 'link';
  }, []);

  const header = useMemo(
    () => (
      <Header
        contentInnerPaddingHorizontal={contentInnerPaddingHorizontal}
        onChangeQuery={onChangeQuery}
        onChangeQueryKey={onChangeQueryKey}
        resetQuery={resetQuery}
        parsedQuery={parsedQuery}
        username={username}
        displayName={displayName}
        isVisitor={isVisitor}
        contentPaddingVertical={contentPaddingVertical}
        headerVisible={headerVisible}
      />
    ),
    [
      onChangeQuery,
      onChangeQueryKey,
      resetQuery,
      parsedQuery,
      username,
      displayName,
      isVisitor,
      contentPaddingVertical,
      contentInnerPaddingHorizontal,
      headerVisible,
    ],
  );

  const context = useMemo<StuffListContextShape>(
    () => ({
      parsedQuery,
      meta,
      data,
      isRefreshing,
      hasFinishedFirstLoad,
      hasMore,
      listRef,
      isVisitor,
      onChangeQuery,
      onChangeQueryKey,
      resetQuery,
      username,
      isPublic,
      columnWidth,
      containerWidth,
      contentPaddingHorizontal,
      stuffTransitionValue: transitionValue,
      url,
      setSelectedLinkIds,
      selectedLinkIds,
      setIsSelecting,
      isSelecting,
    }),
    [
      parsedQuery,
      meta,
      data,
      hasMore,
      listRef,
      isVisitor,
      isRefreshing,
      hasFinishedFirstLoad,
      onChangeQuery,
      onChangeQueryKey,
      resetQuery,
      username,
      isPublic,
      columnWidth,
      containerWidth,
      contentPaddingHorizontal,
      transitionValue,
      url,
      selectedLinkIds,
      setSelectedLinkIds,
      setIsSelecting,
      isSelecting,
    ],
  );

  return (
    <StuffListContext.Provider value={context}>
      <StatusBar
        barStyle={colorScheme === 'light' ? 'dark-content' : 'light-content'}
      />
      <ResourcePreloader username={username} />
      <GestureDetector gesture={pinchGesture}>
        <View style={styles.container} ref={containerRef}>
          <AnimatedMasonaryFlashList
            ref={listRef}
            numColumns={columns}
            data={data}
            renderItem={renderItem}
            getItemType={getItemType}
            overrideItemLayout={overrideItemLayout}
            optimizeItemArrangement
            estimatedItemSize={360}
            estimatedListSize={estimatedListSize}
            contentContainerStyle={{
              paddingHorizontal: contentPaddingHorizontal,
              paddingBottom: 120,
            }}
            contentInset={{
              // NOTE: This also determines where the refresh control renders on iOS. Ideally it'd be further
              // away from the notch, but then the position of the first item couldn't be as close to notch as we want.
              top: insets.top + 12,
            }}
            scrollsToTop
            // drawDistance={
            //   Platform.OS === 'web' ? containerHeight * 10 : undefined
            // }
            ListHeaderComponentStyle={styles.listHeaderContainer}
            ListHeaderComponent={header}
            ListFooterComponent={Footer}
            onRefresh={isVisitor ? undefined : onRefresh}
            refreshing={didPullToRefresh}
            keyboardShouldPersistTaps="always"
            keyboardDismissMode="interactive"
            contentInsetAdjustmentBehavior={isVisitor ? 'always' : 'never'}
            onEndReachedThreshold={3}
            onEndReached={Platform.OS !== 'web' ? onEndReached : undefined}
            onScroll={scrollHandler}
            scrollEventThrottle={1}
            scrollToOverflowEnabled
            keyExtractor={(item: unknown) => {
              return item as string;
            }}
          />
          {Platform.OS !== 'web' && isMobile && !isVisitor && (
            <StatusBarBlurHeader />
          )}
          {showSearchSheet && (
            <SearchSheetBottom
              presentedAnimatedValue={presentedAnimatedValue}
            />
          )}
          <StuffListAction presentedAnimatedValue={presentedAnimatedValue} />
          <SelectToolbar />
        </View>
      </GestureDetector>
      {!isVisitor && <Splash />}
    </StuffListContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 1,
  },
  listHeaderContainer: {
    zIndex: 10000000,
  },
  cell: {
    margin: LINK_PADDING,
  },
  noResultsContainer: {
    marginTop: 120,
    alignItems: 'center',
    justifyContent: 'center',
  },
  refreshControlContainer: {
    alignItems: 'center',
    paddingVertical: 96,
  },
  linkActions: {
    position: 'absolute',
    right: 32,
    bottom: 0,
  },
  linkActionsMobile: {
    right: Platform.select({
      web: 20,
      default: 24,
    }),
    bottom: Platform.select({
      web: 0,
      default: 81,
    }),
  },
  linkActionsIOSVisitor: {
    bottom: 0,
  },
  shareAction: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 96,
  },
  footerText: {
    maxWidth: 340,
    textAlign: 'center',
    fontStyle: 'italic',
    marginBottom: 48,
  },
  statusBarBackground: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },
});

export default React.memo(StuffList);
