import { Feature } from './use-resource/types';
import { useResource } from './use-resource/use-resource';

function useHasFeature(feature: Feature) {
  const { setting } = useResource('setting', 'me', {
    // TODO: useResource should be smart enough to dedupe. It'll require
    // a separate, non-react-state, method for request caching.
    fetch: false,
  });

  return (
    setting &&
    setting.attributes.features &&
    setting.attributes.features.includes(feature)
  );
}

export default useHasFeature;
