import React from 'react';
import Page from 'app/components/Page';
import useCopy from 'app/hooks/use-copy';
import { Platform, StyleSheet } from 'react-native';
import List, { ListItem, ListItemIcon } from 'app/components/List';
import ListItemLabel from 'app/components/List/ListItemLabel';
import { useResource } from 'app/hooks/use-resource/use-resource';
import Checkmark from 'app/components/Icons/Checkmark';
import useAppIcon from 'app/hooks/use-app-icon';
import Image from 'app/components/Image';
import { light } from 'app/constants/colors';
import shadows from 'app/styles/shadows';
import ShadowView, { ShadowType } from 'app/components/ShadowView';
import ThemeView, { makeThemeStyle } from 'app/components/ThemeView';

const AppIcon = () => {
  const copy = useCopy([
    'settingsAppIconTitle',
    'settingsAppIconHelpTextWithoutPlus',
    'settingsAppIconHelpTextOnWeb',
  ]);
  const { currentAppIcon, setAppIconName, appIcons } = useAppIcon();
  const { setting } = useResource('setting', 'me', {
    // TODO: useResource should be smart enough to dedupe. It'll require
    // a separate, non-react-state, method for request caching.
    fetch: false,
  });
  const hasAppIconEntitlement =
    setting && setting.attributes.entitlements.includes('set_app_icon');

  return (
    <Page title={copy.settingsAppIconTitle} fullHeight backButton>
      <List
        help={
          (hasAppIconEntitlement &&
            Platform.OS !== 'ios' &&
            copy.settingsAppIconHelpTextOnWeb) ||
          (!hasAppIconEntitlement && copy.settingsAppIconHelpTextWithoutPlus)
        }
      >
        {appIcons.map(({ name, label, smallIcon, thumbhash }) => (
          <ListItem
            onPress={() => setAppIconName(name)}
            key={name}
            disabled={Platform.OS !== 'ios'}
            withIcon
          >
            <ShadowView type={ShadowType.icon} style={styles.iconContainer}>
              <Image
                source={smallIcon}
                contentFit="cover"
                style={styles.icon}
                placeholder={{
                  thumbhash,
                }}
                transition={{
                  effect: 'cross-dissolve',
                  duration: 200,
                }}
              />
            </ShadowView>
            <ListItemLabel>{label}</ListItemLabel>
            {Platform.OS === 'ios' && currentAppIcon.name == name && (
              <ListItemIcon Icon={Checkmark} />
            )}
          </ListItem>
        ))}
      </List>
    </Page>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    marginVertical: 16,
    borderRadius: 7,
  },
  icon: {
    width: 30,
    height: 30,
    borderRadius: 7,
  },
});

export default AppIcon;
