import useExpand from 'app/hooks/use-expand';
import React, { useCallback, useState } from 'react';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import {
  GestureResponderEvent,
  LayoutRectangle,
  PressableProps,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import { DropdownContainer } from '../Dropdown';
import Modal from '../Modal';
import Pressable from '../Pressable';
import { measure } from 'app/measure';
import { DefaultStyle } from 'react-native-reanimated/lib/typescript/reanimated2/hook/commonTypes';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { ReactNode } from 'react';
import { minBy } from 'lodash';

interface Props extends PressableProps {
  actions: React.ReactNode[];
  children?:
    | ReactNode
    | ((props: {
        open: () => void;
        close: () => void;
      }) => ReactNode | undefined)
    | undefined;
}

const DEBUG_ANIMATIONS = false;

const ContextMenu = ({
  children: _children,
  actions,
  onPressIn: _onPressIn,
  onPressOut: _onPressOut,
  ...props
}: Props) => {
  // NOTE: We measure the layout separately (rather than use
  // transform.value.collapsedWidth, etc), because we need the
  // layout to be available prior to animation starting. Currently
  // in useExpand, we only measure when starting the animation, by
  // which point its too late.
  const [expandedLayout, setExpandedLayout] = useState<LayoutRectangle>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [dir, setDir] = useState<
    'UpLeft' | 'UpRight' | 'DownLeft' | 'DownRight'
  >('DownLeft');
  const [menuLayout, setMenuLayout] = useState<LayoutRectangle>();

  const {
    open,
    close,
    collapsedStyle,
    expandedStyle,
    transform,
    collapsedRef,
    expandedRef,
  } = useExpand({
    toScale: 1.12,
    animationConfig: {
      stiffness: 300,
      mass: DEBUG_ANIMATIONS ? 100 : 1,
      damping: DEBUG_ANIMATIONS ? 300 : 25,
      restDisplacementThreshold: 0.001,
      restSpeedThreshold: 0.01,
    },
  });

  const modalAnimatedValue = useDerivedValue(() =>
    interpolate(
      transform.value.progress,
      [0.5, 1],
      [0, 1],
      Extrapolation.CLAMP,
    ),
  );

  const menuAnimatedStyle = useAnimatedStyle(() => {
    if (!expandedLayout || !menuLayout) {
      return {};
    }

    // Calculate the translate offsets to scale about the correct corner
    const translateY = menuLayout.height * (dir.startsWith('Up') ? -0.5 : 0.5);
    const translateX = menuLayout.width * (dir.endsWith('Left') ? -0.5 : 0.5);

    const style: DefaultStyle = {
      opacity: transform.value.progress,
      transform: [
        {
          translateY: -translateY,
        },
        {
          translateX: -translateX,
        },
        {
          // NOTE: This is a hack. When the animation doesn't scale to 1.12, which happens
          // when the scaling would make it larger than the viewport, this causes the menu
          // to be too small.
          scale: interpolate(transform.value.progress, [0, 1], [0, 1 / 1.12]),
        },
        {
          translateY,
        },
        {
          translateX,
        },
      ],
    };

    // Use margin to position the menu in the right place
    if (dir.startsWith('Down')) {
      style.marginTop = 12;
    } else {
      style.marginTop = -expandedLayout.height - menuLayout.height - 12;
    }

    return style;
  }, [dir, expandedLayout, transform, menuLayout]);
  const windowDimensions = useWindowDimensions();

  const updateExpandedLayout = useCallback(async () => {
    if (!collapsedRef.current) {
      return;
    }

    const { pageX, pageY, height, width } = await measure(
      collapsedRef,
      transform.value.scale,
    );

    const padding = 16;

    const furtherUp = padding;
    const furtherLeft = padding;
    const furthestRight = windowDimensions.width - width - padding;
    const furthestDown = windowDimensions.height - height - padding;

    // Calculate the possible closest positions
    const screenTopLeft: [number, number] = [furtherLeft, furtherUp];
    const screenTopRight: [number, number] = [furthestRight, furtherUp];
    const screenBottomLeft: [number, number] = [furtherLeft, furthestDown];
    const screenBottomRight: [number, number] = [furthestRight, furthestDown];
    const middle: [number, number] = [
      Math.min(
        Math.max(pageX, padding),
        windowDimensions.width - padding - width,
      ),
      Math.min(
        Math.max(pageY, padding),
        windowDimensions.height - padding - height,
      ),
    ];

    // Collect all the possible positions we could move the expanded item to.
    const targets: [number, number][] = [
      screenTopLeft,
      screenTopRight,
      screenBottomLeft,
      screenBottomRight,
      middle,
    ];

    // Calculate the closest of all the possible targets.
    const dist = (target: [number, number]) =>
      Math.sqrt((target[0] - pageX) ** 2 + (target[1] - pageY) ** 2);
    const [x, y] = minBy(targets, (target) => dist(target)) || [];

    if (!x || !y) {
      return;
    }

    // Based on available space, chose a direction to expand the menu into
    const topSpace = y - padding;
    const bottomSpace = windowDimensions.height - y - height - padding;
    const leftSpace = x - padding;
    const rightSpace = windowDimensions.width - x - width - padding;

    if (topSpace >= bottomSpace) {
      if (leftSpace >= rightSpace) {
        setDir('UpLeft');
      } else {
        setDir('UpRight');
      }
    } else {
      if (leftSpace >= rightSpace) {
        setDir('DownLeft');
      } else {
        setDir('DownRight');
      }
    }

    setExpandedLayout({
      x,
      y,
      width,
      height,
    });
  }, [collapsedRef, windowDimensions, transform]);

  const onClose = useCallback(() => {
    close(() => {
      setIsExpanded(false);
    });
  }, [close]);

  const onOpen = useCallback(
    (timeout: number = 5) => {
      ReactNativeHapticFeedback.trigger('impactHeavy');
      setTimeout(() => {
        setIsExpanded(true);
        setTimeout(open);
      }, Math.max(timeout, 5));
    },
    [open],
  );

  const triggerOpen = useCallback(() => {
    updateExpandedLayout();
    onOpen();
  }, [updateExpandedLayout, onOpen]);

  const onPressIn = useCallback(
    (event: GestureResponderEvent) => {
      updateExpandedLayout();

      if (_onPressIn) {
        _onPressIn(event);
      }

      const scale = 1.03;

      transform.value = withSpring(
        {
          ...transform.value,
          scale,
        },
        {
          mass: 1,
          damping: 400,
          stiffness: 100,
          restDisplacementThreshold: 0.001,
          restSpeedThreshold: 0.01,
        },
      );
    },
    [_onPressIn, updateExpandedLayout, transform],
  );

  const onPressOut = useCallback(
    (event: GestureResponderEvent) => {
      if (_onPressOut) {
        _onPressOut(event);
      }

      if (!transform.value.progress) {
        transform.value = withSpring(
          {
            ...transform.value,
            scale: 1,
          },
          {
            mass: 1,
            damping: 50,
            stiffness: 200,
            restDisplacementThreshold: 0.001,
            restSpeedThreshold: 0.01,
          },
        );
      }
    },
    [transform, _onPressOut],
  );

  const children =
    typeof _children === 'function'
      ? _children({ open: triggerOpen, close: onClose })
      : _children;

  return (
    <>
      <Pressable
        {...props}
        style={[collapsedStyle, props.style]}
        ref={collapsedRef}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        onLongPress={() => onOpen()}
        delayLongPress={250}
      >
        <Animated.View>{children}</Animated.View>
      </Pressable>
      <Modal
        visible={isExpanded}
        onRequestClose={onClose}
        darkenBackground
        centered={false}
        animatedValue={modalAnimatedValue}
        handleAnimations={false}
        animateContent={false}
        hostName="contextMenu"
      >
        <View
          pointerEvents="box-none"
          style={
            expandedLayout && {
              transform: [
                {
                  translateX: expandedLayout.x,
                },
                {
                  translateY: expandedLayout.y,
                },
              ],
            }
          }
        >
          <Animated.View
            style={[
              expandedStyle,
              styles.container,
              props.style,
              expandedLayout && {
                // We constrain the expanded dimensions to exactly those of
                // the collapsed so that we can allow dimensions of the collapsed
                // to be computed within its layout (rather than having to manually
                // specify height/width so that it works in expanded state).
                width: expandedLayout.width,
                height: expandedLayout.height,
              },
            ]}
            ref={expandedRef}
          >
            {children}
            <DropdownContainer
              style={[
                styles.menuContainer,
                {
                  alignSelf: dir.endsWith('Right') ? 'flex-start' : 'flex-end',
                },
              ]}
              animatedStyle={menuAnimatedStyle}
              onLayout={(event) => setMenuLayout(event.nativeEvent.layout)}
            >
              {React.Children.map(actions, (action) =>
                React.cloneElement(action, {
                  ...action.props,
                  onPress: () => {
                    onClose();
                    setTimeout(() => {
                      action.props.onPress();
                    }, 300);
                  },
                }),
              )}
            </DropdownContainer>
          </Animated.View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'stretch',
    position: 'relative',
  },
  menuContainer: {
    alignSelf: 'flex-end',
    right: 0,
    maxWidth: 230,
    minWidth: 200,
    zIndex: -1,
  },
});

export default ContextMenu;
