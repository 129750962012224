import React, { useContext, useEffect } from 'react';
import StuffListContext from './StuffListContext';
import {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import Text, { TextType } from '../Text';
import useCopy from 'app/hooks/use-copy';
import pluralize from 'pluralize';
import { StyleSheet, View } from 'react-native';
import { makeThemeStyle } from '../ThemeView';
import { Portal } from '@gorhom/portal';
import BlurView from '../BlurView';
import ShadowView, { ShadowType } from '../ShadowView';
import EllipsisButton from '../EllipsisButton';
import ContextMenu from '../ContextMenu/ContextMenu';
import { DropdownItem } from '../Dropdown';
import { useNavigation } from '@react-navigation/native';
import Routes from '../Navigator/ROUTES';
import { bottomSheetAnimationConfig } from '../SearchSheet/SearchSheetBottom';
import PlusSquare from '../Icons/PlusSquare';

const SelectToolbar = () => {
  const { selectedLinkIds, isSelecting, setIsSelecting } =
    useContext(StuffListContext);
  const copy = useCopy([
    'stuffListSelectToolbarItem',
    'stuffListSelectToolbarSelected',
    'stuffListSelectToolbarAddToCollection',
  ]);
  const animatedValue = useSharedValue(0);
  const navigation = useNavigation();

  useEffect(() => {
    animatedValue.value = withSpring(
      isSelecting ? 1 : 0,
      // We use the same config as the bottom sheet so the animations match on iOS
      bottomSheetAnimationConfig,
    );
  }, [animatedValue, isSelecting]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [
        { translateY: interpolate(animatedValue.value, [0, 1], [40, 0]) },
      ],
      opacity: animatedValue.value,
    }),
    [animatedValue],
  );

  return (
    <Portal hostName="bottomSheet">
      <View style={styles.positionContainer} pointerEvents="box-none">
        <ContextMenu
          actions={[
            <DropdownItem
              label={copy.stuffListSelectToolbarAddToCollection}
              Icon={PlusSquare}
              last
              onPress={() => {
                setIsSelecting(false);
                navigation.navigate(Routes.ADD_TO_COLLECTION, {
                  links: Array.from(selectedLinkIds),
                });
              }}
              key="collection"
            />,
          ]}
        >
          {({ open }) => (
            <ShadowView
              type={ShadowType.big}
              animatedStyle={animatedStyle}
              lightStyle={styles.containerLight}
              darkStyle={styles.containerDark}
              borderRadius={23}
            >
              <BlurView amount={24} style={styles.blurContainer}>
                <Text type={TextType.body}>
                  {`${selectedLinkIds.size} ${pluralize(
                    copy.stuffListSelectToolbarItem,
                  )} ${copy.stuffListSelectToolbarSelected}`}
                </Text>
                <EllipsisButton onPress={open} width={30} height={30} />
              </BlurView>
            </ShadowView>
          )}
        </ContextMenu>
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  positionContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ...makeThemeStyle(
    'container',
    {
      borderRadius: 23,
      flexDirection: 'row',
      alignItems: 'center',
    },
    {
      backgroundColor: 'LayeredOffWhite',
    },
  ),
  blurContainer: {
    paddingLeft: 38,
    paddingRight: 8,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 23,
    gap: 16,
  },
});

export default SelectToolbar;
